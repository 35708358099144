import React, { useState, useEffect } from 'react';
import { CheckCircle, Loader, AlertCircle } from 'lucide-react';

const AnimatedConfirmation = ({
  status,
  booking,
  onDownloadInvoice,
  countdown,
  onNavigateHome,
}) => {
  const [showContent, setShowContent] = useState(false);
  const [showCheckmark, setShowCheckmark] = useState(false);

  useEffect(() => {
    if (status === 'success') {
      // Trigger checkmark animation first
      setShowCheckmark(true);
      // Then show content after checkmark animation
      setTimeout(() => setShowContent(true), 1000);
    }
  }, [status]);

  if (status === 'loading') {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-white">
        <div className="text-center">
          <Loader className="w-16 h-16 animate-spin text-blue-500 mx-auto mb-4" />
          <p className="text-gray-600 text-lg animate-pulse">
            Verifying your booking...
          </p>
        </div>
      </div>
    );
  }

  if (status === 'error') {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-white">
        <div className="text-center transform animate-fade-in-up">
          <AlertCircle className="w-16 h-16 text-red-500 mx-auto mb-4" />
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">
            Booking Verification Failed
          </h2>
          <button
            onClick={() => window.location.reload()}
            className="bg-blue-500 text-white px-8 py-3 rounded-lg hover:bg-blue-600 transition-all transform hover:scale-105"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-white overflow-auto">
      <div className="max-w-2xl w-full mx-4">
        {/* Animated Success Checkmark */}
        <div className={`text-center mb-8 transform transition-all duration-1000 ${showCheckmark ? 'scale-100 opacity-100' : 'scale-50 opacity-0'}`}>
          <svg className="w-24 h-24 mx-auto" viewBox="0 0 100 100">
            <circle
              className="text-green-500 stroke-current fill-none"
              strokeWidth="5"
              cx="50"
              cy="50"
              r="45"
              style={{
                strokeDasharray: 283,
                strokeDashoffset: showCheckmark ? 0 : 283,
                transition: 'stroke-dashoffset 0.6s ease-out'
              }}
            />
            <path
              className="text-green-500 stroke-current fill-none"
              strokeWidth="5"
              d="M30 50 L45 65 L70 35"
              style={{
                strokeDasharray: 75,
                strokeDashoffset: showCheckmark ? 0 : 75,
                transition: 'stroke-dashoffset 0.6s ease-out 0.4s'
              }}
            />
          </svg>
        </div>

        {/* Booking Details Content */}
        <div className={`transform transition-all duration-700 ${showContent ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'}`}>
          <div className="bg-white rounded-xl shadow-xl p-8">
            <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">
              Booking Confirmed!
            </h2>

            <div className="space-y-6">
              <div className="border-b border-gray-200 pb-4">
                <h3 className="text-xl font-medium text-gray-800 mb-3">
                  Booking Information
                </h3>
                <p className="text-gray-600">Booking ID: {booking.bookingId}</p>
                <p className="text-gray-600">
                  Amount Paid: ${booking.amount.toFixed(2)}
                </p>
              </div>

              <div className="border-b border-gray-200 pb-4">
                <h3 className="text-xl font-medium text-gray-800 mb-3">
                  Trek Details
                </h3>
                <p className="text-gray-600">{booking.trekDetails.name}</p>
                <p className="text-gray-600">
                  Route: {booking.routeDetails.routeName}
                </p>
                <p className="text-gray-600">
                  Duration: {booking.routeDetails.numberOfDays} days
                </p>
              </div>

              <button
                onClick={onDownloadInvoice}
                className="w-full bg-blue-500 text-white py-4 rounded-lg hover:bg-blue-600 transition-all transform hover:scale-105 font-medium"
              >
                Download Invoice
              </button>

              <div className="text-center mt-8">
                <p className="text-gray-600 mb-3">
                  Redirecting to home in{" "}
                  <span className="font-bold text-blue-500">{countdown}</span>{" "}
                  seconds...
                </p>
                <button
                  onClick={onNavigateHome}
                  className="bg-gray-100 text-gray-700 px-8 py-3 rounded-lg hover:bg-gray-200 transition-all transform hover:scale-105"
                >
                  Go to Home Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnimatedConfirmation;