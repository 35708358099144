import React, { useState, useEffect, useContext } from "react";
import { IoIosArrowDown, IoIosStar } from "react-icons/io";
import { toast } from "sonner";
import { useLocation, useNavigate } from "react-router-dom";
import DatePicker from "../Common/DatePicker";
import PaymentModalWrapper from "../PaymentModalWrapper";
import useAuth from "../../services/useAuth";
import { UserContext } from "../../context/UserContext";

const PackageDetailForm = ({
  details,
  clientSecret,
  setClientSecret,
  setPayment,
  setBookingData,
  selectedRoute,
  initialDate,
}) => {
  const [checkInDate, setCheckInDate] = useState(() => {
    if (initialDate) {
      return new Date(initialDate);
    }
    return new Date();
  });

  const [checkOutDate, setCheckOutDate] = useState(() => {
    const routeDuration = selectedRoute?.numberOfDays || 1;
    const startDate = initialDate ? new Date(initialDate) : new Date();
    return new Date(startDate.getTime() + routeDuration * 24 * 60 * 60 * 1000);
  });

  const [guests, setGuests] = useState(1);
  const [customGuests, setCustomGuests] = useState("");
  const [showCustomGuestInput, setShowCustomGuestInput] = useState(false);
  const [includeMeals, setIncludeMeals] = useState(false);
  const [includePorter, setIncludePorter] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [bookingId, setBookingId] = useState(null);
  const [priceBreakdown, setPriceBreakdown] = useState({
    basePrice: 0,
    discount: 0,
    gst: 0,
    serviceFee: 0,
    insurance: 0,
    bookingFee: 0,
  });
  const [currency, setCurrency] = useState('USD');
  const [exchangeRate, setExchangeRate] = useState(null);
  const [isLoadingRate, setIsLoadingRate] = useState(false);

  const { user } = useContext(UserContext);
  const userId = user?.id || null;
  const trekId = useLocation().pathname.split("/")[2];
  const navigate = useNavigate();
  const { api } = useAuth();

  const getBasePrice = () => {
    if (!selectedRoute) {
      return details?.routes?.[0]?.pricePerHead || 0;
    }
    return selectedRoute.pricePerHead || 0;
  };

  useEffect(() => {
    const calculatePrice = () => {
      const basePrice = getBasePrice();
      const numberOfGuests = showCustomGuestInput ? (customGuests ? parseInt(customGuests) : 1) : guests;
      const subtotal = basePrice * numberOfGuests;

      const {
        groupDiscountThreshold,
        groupDiscountPercentage,
        isGroupDiscountApplicable,
      } = details.paymentDetails.discount;

      let discount = 0;
      if (
        isGroupDiscountApplicable &&
        numberOfGuests >= groupDiscountThreshold
      ) {
        discount = (subtotal * groupDiscountPercentage) / 100;
      }

      const { gst, serviceFee, insurancePerPerson, bookingFee } =
        details.paymentDetails.charges;
      const gstAmount = ((subtotal - discount) * gst) / 100;
      const serviceFeeAmount = ((subtotal - discount) * serviceFee) / 100;
      const insuranceTotal = insurancePerPerson * numberOfGuests;

      setPriceBreakdown({
        basePrice: subtotal,
        discount: discount,
        gst: gstAmount,
        serviceFee: serviceFeeAmount,
        insurance: insuranceTotal,
        bookingFee: bookingFee,
      });

      const finalPrice =
        subtotal -
        discount +
        gstAmount +
        serviceFeeAmount +
        insuranceTotal +
        bookingFee;
      setTotalPrice(finalPrice);
    };

    calculatePrice();
  }, [
    guests,
    customGuests,
    showCustomGuestInput,
    selectedRoute,
    details,
    includeMeals,
    includePorter,
  ]);

  useEffect(() => {
    const fetchExchangeRate = async () => {
      if (currency === 'USD') {
        setExchangeRate(1);
        return;
      }

      setIsLoadingRate(true);
      try {
        const response = await fetch('https://api.exchangerate-api.com/v4/latest/USD');
        const data = await response.json();
        setExchangeRate(data.rates.NPR);
      } catch (error) {
        console.error('Error fetching exchange rate:', error);
        toast.error('Unable to fetch exchange rate. Using default conversion.');
        setExchangeRate(132.95);
      } finally {
        setIsLoadingRate(false);
      }
    };

    fetchExchangeRate();
  }, [currency]);

  useEffect(() => {
    const routeDuration = selectedRoute?.numberOfDays || 1;
    const newCheckOutDate = new Date(
      checkInDate.getTime() + routeDuration * 24 * 60 * 60 * 1000
    );
    setCheckOutDate(newCheckOutDate);
  }, [selectedRoute, checkInDate]);

  const convertPrice = (price) => {
    if (!exchangeRate || currency === 'USD') return price;
    return price * exchangeRate;
  };

  const formatPrice = (price) => {
    const convertedPrice = convertPrice(price);
    if (isLoadingRate) return 'Loading...';
    return `${currency === 'NPR' ? 'NPR' : '$'} ${convertedPrice.toFixed(2)}`;
  };

  const toggleCurrency = () => {
    setCurrency(prev => prev === 'USD' ? 'NPR' : 'USD');
  };

  const handleGuestsChange = (e) => {
    const value = e.target.value;
    if (value === "custom") {
      setShowCustomGuestInput(true);
      setCustomGuests("");
      setGuests(1);
    } else {
      setShowCustomGuestInput(false);
      setGuests(Number(value));
      setCustomGuests("");
    }
  };

  const handleCustomGuestsChange = (e) => {
    const value = e.target.value;
    if (value === "" || (Number(value) >= 1 && Number(value) <= 100)) {
      setCustomGuests(value);
    }
  };

  const formatDateWithoutYear = (date) => {
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
    });
  };

  const handleReserve = async () => {
    if (!userId) {
      toast.error("Please log in to create a booking.");
      return;
    }

    if (!selectedRoute) {
      toast.error("Please select a route before booking.");
      return;
    }

    if (!checkInDate || !checkOutDate) {
      toast.error("Please select check-in and check-out dates.");
      return;
    }

    if (checkOutDate <= checkInDate) {
      toast.error("Check-out date must be after the check-in date.");
      return;
    }

    const numberOfGuests = showCustomGuestInput ? (customGuests ? parseInt(customGuests) : 0) : guests;
    if (!numberOfGuests || numberOfGuests <= 0 || numberOfGuests > 100) {
      toast.error("Please enter a valid number of guests.");
      return;
    }

    setCurrency('USD');

    const routeDetails = {
      routeName: selectedRoute?.name,
      numberOfDays: selectedRoute?.numberOfDays,
      pricePerHead: getBasePrice(),
    };

    const bookingData = {
      userId,
      trekId,
      routeDetails,
      bookingDetails: {
        startDate: checkInDate,
        endDate: checkOutDate,
        totalGuests: numberOfGuests,
      },
      guestDetails: {
        primaryGuest: {
          name: user?.name,
          age: 0,
          gender: user?.gender,
        },
        additionalGuests: [],
      },
      payment: {
        baseAmount: totalPrice,
        finalAmount: totalPrice,
      },
      includeMeals,
      includePorter,
      priceBreakdown: {
        ...priceBreakdown,
        basePrice: getBasePrice() * numberOfGuests,
      },
    };

    try {
      setIsModalOpen(false);
      navigate("/booking", {
        state: {
          bookingData,
          details,
          selectedRoute,
        },
      });
    } catch (error) {
      toast.error("Error while processing your booking. Please try again.");
      console.error(error);
    }
  };

  return (
    <div className="w-full flex flex-col gap-1.5">
      {!userId && (
        <p className="text-sm text-red-500 py-2">
          Please log in to create a booking.
        </p>
      )}
      <div className="w-full flex items-center justify-between">
        <h1 className="text-xl font-semibold popp">
          {formatPrice(getBasePrice())} / Person
        </h1>
        <div className="flex items-center gap-1">
          <IoIosStar className="text-yellow-500" />
        </div>
      </div>

      <div className="grid grid-cols-2 items-center gap-5 pt-4 justify-between w-full ">
        <div className="w-full flex flex-col items-start gap-1">
          <label className="text-sm font-semibold popp">Check-in Date</label>
          <DatePicker formData={checkInDate} setFormData={setCheckInDate} />
        </div>
        <div className="w-full flex flex-col items-start gap-1 border-black/30 border-b">
          <label className="text-sm font-semibold popp">Check-out Date</label>
          <div className="flex w-full items-center justify-between bg-transparentmt-2 p-2">
            <span className="popp text-sm text-black/50">
              {formatDateWithoutYear(checkOutDate)}
            </span>
            <div className="text-black/30">
              <IoIosArrowDown />
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col py-4 items-start gap-1">
        <label className="text-sm font-semibold popp">Guests</label>
        <select
          value={showCustomGuestInput ? "custom" : guests}
          onChange={handleGuestsChange}
          className="w-full text-sm p-1 border-b popp border-gray-300"
        >
          {[...Array(10)].map((_, i) => (
            <option key={i} value={i + 1}>
              {i + 1} Guest{i > 0 ? "s" : ""}
            </option>
          ))}
          <option value="custom">Custom number of guests</option>
        </select>

        {showCustomGuestInput && (
          <input
            type="number"
            value={customGuests}
            onChange={handleCustomGuestsChange}
            placeholder="Enter number of guests"
            min="1"
            max="100"
            className="w-full mt-2 text-sm p-1 border-b popp border-gray-300"
          />
        )}
      </div>

      <div className="pricing flex py-4 flex-col items-center w-full">
        <div className="w-full flex py-2 items-center justify-between">
          <p className="text-sm popp">
            Base Price ({showCustomGuestInput ? (customGuests || 1) : guests} guests)
          </p>
          <p className="text-sm popp">
            {formatPrice(priceBreakdown.basePrice)}
          </p>
        </div>

        {priceBreakdown.discount > 0 && (
          <div className="w-full flex py-2 items-center justify-between">
            <p className="text-sm popp">
              Group Discount (
              {details.paymentDetails.discount.groupDiscountPercentage}%)
            </p>
            <p className="text-sm font-semibold popp text-green-600">
              -{formatPrice(priceBreakdown.discount)}
            </p>
          </div>
        )}

        <div className="w-full flex py-2 items-center justify-between">
          <p className="text-sm popp">
            GST ({details.paymentDetails.charges.gst}%)
          </p>
          <p className="text-sm popp">{formatPrice(priceBreakdown.gst)}</p>
        </div>

        <div className="w-full flex py-2 items-center justify-between">
          <p className="text-sm popp">
            Service Fee ({details.paymentDetails.charges.serviceFee}%)
          </p>
          <p className="text-sm popp">
            {formatPrice(priceBreakdown.serviceFee)}
          </p>
        </div>

        <div className="w-full flex py-2 items-center justify-between">
          <p className="text-sm popp">Insurance (per person)</p>
          <p className="text-sm popp">
            {formatPrice(priceBreakdown.insurance)}
          </p>
        </div>

        <div className="w-full flex py-2 items-center justify-between">
          <p className="text-sm popp">Booking Fee</p>
          <p className="text-sm popp">
            {formatPrice(priceBreakdown.bookingFee)}
          </p>
        </div>

        <hr className="w-full border-1 border-gray-200" />
        <div className="w-full flex py-2 items-center justify-between">
          <p className="text-lg font-semibold popp">Total ({currency})</p>
          <p className="text-lg font-semibold popp">
            {formatPrice(totalPrice)}
          </p>
        </div>
      </div>
      
      <button
        onClick={toggleCurrency}
        className="popp px-5 p-2 mb-2 rounded-[10px] bg-gray-200 hover:bg-gray-300"
      >
        Convert to {currency === 'USD' ? 'NPR' : 'USD'}
      </button>

      <button
        onClick={handleReserve}
        disabled={!userId || totalPrice === 0 || !selectedRoute || (showCustomGuestInput && !customGuests)}
        className={`popp px-5 p-2 rounded-[10px] ${
          !userId || totalPrice === 0 || !selectedRoute || (showCustomGuestInput && !customGuests)
            ? "bg-gray-400 cursor-not-allowed"
            : "bg-[#70B4E8] text-white"
        }`}
      >
        Reserve
      </button>
      <p className="text-sm popp p-2 text-center">You won't be charged yet</p>

      {isModalOpen && clientSecret && (
        <PaymentModalWrapper
          clientSecret={clientSecret}
          onSuccess={() => {
            setIsModalOpen(false);
            navigate("/booking-success");
          }}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </div>
  );
};

export default PackageDetailForm;