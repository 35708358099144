import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
  AddressElement,
} from "@stripe/react-stripe-js";
import { Loader } from "lucide-react";
import useAuth from "../../services/useAuth";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

// Initialize Stripe with singleton pattern
const getStripe = (() => {
  let stripePromise = null;
  return async () => {
    if (!stripePromise) {
      const key = import.meta.env.VITE_STRIPE_PUBLIC_KEY;
      if (!key) {
        throw new Error("Stripe public key is not configured");
      }
      stripePromise = await loadStripe(key);
    }
    return stripePromise;
  };
})();

const PaymentForm = ({
  clientSecret,
  bookingId,
  onSuccess,
  onError,
  customerDetails,
  id
}) => {
    const {api} = useAuth();
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const [isProcessing, setIsProcessing] = useState(false);
    const [error, setError] = useState(null);
    const [billingDetails, setBillingDetails] = useState({
      name: customerDetails?.name || "",
      email: customerDetails?.email || "",
      phone: customerDetails?.phone || "",
      address: null,
    });
  
    // Improved validation with specific error messages
    const validateBillingDetails = () => {
      const errors = [];
      if (!billingDetails.name?.trim()) {
        errors.push("Full name is required");
      }
      if (!billingDetails.address) {
        errors.push("Complete billing address is required");
      }
      if (billingDetails.email && !/\S+@\S+\.\S+/.test(billingDetails.email)) {
        errors.push("Please enter a valid email address");
      }
      if (errors.length > 0) {
        throw new Error(errors.join(". "));
      }
    };
  
    // Enhanced payment verification with better error handling
    const verifyPayment = async (paymentIntentId) => {
      try {
        const response = await api.post("/api/bookings/verify-payment", {
          paymentIntentId,
          id
        });
  
        if (response.status !== 200 || response.data.status !== "success") {
          throw new Error(response.data.message || "Payment verification failed");
        }
  
        return response.data;
      } catch (error) {
        console.error("Payment verification error:", error);
        throw error;
      }
    };
  
  
    const handleSubmit = async (event) => {
      event.preventDefault();
      setError(null);
  
      try {
        if (!stripe || !elements || !clientSecret) {
          throw new Error("Payment system not initialized properly");
        }
  
        setIsProcessing(true);
  
        const { error: submitError } = await elements.submit();
        if (submitError) throw submitError;
  
        const { error: paymentError, paymentIntent } = await stripe.confirmPayment({
          elements,
          confirmParams: {
            return_url: `${window.location.origin}/booking/confirmation/${id}`,
            payment_method_data: {
              billing_details: billingDetails
            },
          },
          redirect: "if_required",
        });
  
        if (paymentError) throw paymentError;
  
        if (!paymentIntent) {
          throw new Error("No payment intent returned");
        }
  
        if (paymentIntent.status === "succeeded") {
          await verifyPayment(paymentIntent.id);
          toast.success("Payment successful");
          navigate(`/booking/confirmation/${id}`, {
            state: { paymentIntent }
          });
        } else {
          throw new Error(`Unexpected payment status: ${paymentIntent.status}`);
        }
      } catch (err) {
        console.error("Payment processing error:", err);
        setError(err.message || "Payment processing failed");
        onError(err);
      } finally {
        setIsProcessing(false);
      }
    };


  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {error && (
        <div className="p-4 bg-red-50 border border-red-200 rounded-lg text-red-800">
          <p className="text-sm font-medium">{error}</p>
        </div>
      )}

      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Full Name *
          </label>
          <input
            type="text"
            value={billingDetails.name}
            onChange={(e) =>
              setBillingDetails((prev) => ({ ...prev, name: e.target.value }))
            }
            className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Email
          </label>
          <input
            type="email"
            value={billingDetails.email}
            onChange={(e) =>
              setBillingDetails((prev) => ({ ...prev, email: e.target.value }))
            }
            className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Phone Number
          </label>
          <input
            type="tel"
            value={billingDetails.phone}
            onChange={(e) =>
              setBillingDetails((prev) => ({ ...prev, phone: e.target.value }))
            }
            className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Billing Address *
          </label>
          <AddressElement
            options={{
              mode: "billing",
              fields: {
                phone: "never",
              },
              display: {
                name: "full", // Changed from 'never' to 'full'
              },
              defaultValues: {
                name: billingDetails.name,
              },
            }}
            onChange={(event) => {
              if (event.complete) {
                setBillingDetails((prev) => ({
                  ...prev,
                  address: event.value.address,
                }));
              }
            }}
          />
        </div>
      </div>

      <PaymentElement
        options={{
          layout: "tabs",
          wallets: {
            applePay: "never",
            googlePay: "never",
          },
          fields: {
            billingDetails: "never",
          },
        }}
      />

      <button
        type="submit"
        disabled={isProcessing || !stripe || !elements}
        className="w-full bg-blue-600 text-white py-3 rounded-lg hover:bg-blue-700 disabled:bg-gray-400 disabled:cursor-not-allowed flex items-center justify-center gap-2"
      >
        {isProcessing ? (
          <>
            <Loader className="animate-spin" />
            <span>Processing Payment...</span>
          </>
        ) : (
          "Pay Now"
        )}
      </button>
    </form>
  );
};

const StripePaymentHandler = ({
  clientSecret,
  bookingId,
  onPaymentSuccess,
  onPaymentError,
  customerDetails,
  id
}) => {
  const [stripeInstance, setStripeInstance] = useState(null);
  const [initError, setInitError] = useState(null);

  React.useEffect(() => {
    const initStripe = async () => {
      try {
        const stripe = await getStripe();
        setStripeInstance(stripe);
      } catch (err) {
        setInitError(err.message);
        onPaymentError(err);
      }
    };

    if (!stripeInstance && !initError) {
      initStripe();
    }
  }, [stripeInstance, initError, onPaymentError]);

  if (initError) {
    return (
      <div className="p-4 bg-red-50 border border-red-200 rounded-lg">
        <p className="text-red-800 text-sm font-medium">
          Failed to initialize payment system: {initError}
        </p>
      </div>
    );
  }

  if (!clientSecret) {
    return (
      <div className="p-4 bg-red-50 border border-red-200 rounded-lg">
        <p className="text-red-800 text-sm font-medium">
          Payment session not initialized properly. Please try again.
        </p>
      </div>
    );
  }

  if (!stripeInstance) {
    return (
      <div className="flex items-center justify-center p-4 gap-2">
        <Loader className="animate-spin" />
        <span>Loading payment system...</span>
      </div>
    );
  }

  return (
    <Elements
      stripe={stripeInstance}
      options={{
        clientSecret,
        appearance: {
          theme: "stripe",
          variables: {
            colorPrimary: "#4997D3",
            colorBackground: "#ffffff",
            colorText: "#1a1f36",
            colorDanger: "#df1b41",
            borderRadius: "10px",
          },
        },
      }}
    >
      <PaymentForm
        clientSecret={clientSecret}
        bookingId={bookingId}
        id={id}
        onSuccess={onPaymentSuccess}
        onError={onPaymentError}
        customerDetails={customerDetails}
      />
    </Elements>
  );
};

export default StripePaymentHandler;
