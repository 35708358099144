import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { toast } from "sonner";
import { Loader, AlertCircle } from "lucide-react";
import useAuth from "../../services/useAuth";
import generateInvoice from "../../utils/generateInvoice";
import AnimatedConfirmation from "../Common/AnimatedConfirmation";

const BookingConfirmation = () => {
  const { bookingId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { api } = useAuth();

  const [status, setStatus] = useState("loading");
  const [bookingDetails, setBookingDetails] = useState(null);
  const [error, setError] = useState("");
  const [countdown, setCountdown] = useState(15);
  const [retryCount, setRetryCount] = useState(0);

  const userData = JSON.parse(localStorage.getItem("user"));

  const fetchBookingDetails = async () => {
    try {
      if (!location.state?.paymentIntent && !bookingId) {
        throw new Error("No booking identifier provided");
      }

      console.log("paymentIntentId :",location.state.paymentIntent.id);
      console.log("bookingId : ",bookingId);
      const response = await api.get(`/api/bookings/${bookingId}`);

      if (response.data.status === 'success') {
        const booking = response.data.data.booking;
        
        const formattedBooking = {
          bookingId: booking._id,
          amount: booking.payment.finalAmount,
          trekDetails: {
            name: booking.trek.name,
          },
          routeDetails: {
            routeName: booking.routeDetails.routeName,
            numberOfDays: booking.routeDetails.numberOfDays,
          },
          paymentStatus: booking.payment.status,
        };

        if (formattedBooking.paymentStatus === 'Confirmed') {
          setBookingDetails(formattedBooking);
          setStatus("success");
          return true;
        }
        
        if (retryCount < 3) {
          return false;
        }
        
        setError("Payment confirmation timed out");
        setStatus("error");
        return false;
      }

      throw new Error("Failed to fetch booking details");
    } catch (err) {
      console.error("Booking verification error:", err);
      setError(err.message || "Failed to verify booking");
      setStatus("error");
      return false;
    }
  };


  useEffect(() => {
    let pollingInterval;
    
    const pollBookingStatus = async () => {
      const isConfirmed = await fetchBookingDetails();
      
      if (!isConfirmed && retryCount < 3) {
        setRetryCount(prev => prev + 1);
      } else {
        clearInterval(pollingInterval);
      }
    };

    pollingInterval = setInterval(pollBookingStatus, 2000);
    pollBookingStatus();

    return () => clearInterval(pollingInterval);
  }, [bookingId, retryCount, location.state]);


  useEffect(() => {
    if (status === "success") {
      const timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            navigate("/");
            clearInterval(timer);
          }
          return prev - 1;
        });
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [status, navigate]);

  const handleDownloadInvoice = () => {
    try {
      if (!bookingDetails) throw new Error("Booking details not available");
      const doc = generateInvoice(bookingId, bookingDetails, userData);
      doc.save(`booking-${bookingId}.pdf`);
      toast.success("Invoice downloaded successfully!");
    } catch (err) {
      console.error("Error generating invoice:", err);
      toast.error("Failed to generate invoice. Please try again.");
    }
  };

  if (status === "loading") {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <Loader className="w-8 h-8 animate-spin text-blue-500 mb-4" />
        <p className="text-gray-600">Verifying your booking...</p>
      </div>
    );
  }

  if (status === "error") {
    return (
      <div className="flex flex-col items-center justify-center min-h-[400px] p-6">
        <AlertCircle className="w-12 h-12 text-red-500 mb-4" />
        <h2 className="text-xl font-semibold text-gray-800 mb-2">
          Booking Verification Failed
        </h2>
        <p className="text-gray-600 text-center mb-4">{error}</p>
        <button
          onClick={() => window.location.reload()}
          className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 transition-colors"
        >
          Try Again
        </button>
      </div>
    );
  }

  return (
    <AnimatedConfirmation
      status={status}
      booking={bookingDetails}
      onDownloadInvoice={handleDownloadInvoice}
      countdown={countdown}
      onNavigateHome={() => navigate("/")}
    />
  );
};

export default BookingConfirmation;