import { MapContainer, TileLayer, Marker, Popup, useMap, Polyline, Tooltip } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { useState, useEffect } from "react";


const TILE_LAYER_URL = "https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png";
const TILE_LAYER_OPTIONS = {
  attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
  maxZoom: 19,
  subdomains: 'abcd',
  // Force English language
  language: 'en',
  className: 'map-tiles-english'
};

// Rest of the component code remains the same
const customIcon = (type) => {
  const colors = {
    start: "#22c55e",
    end: "#ef4444",   
    stop: "#3b82f6"   
  };

  return L.divIcon({
    html: `
      <svg width="48" height="48" viewBox="0 0 48 48">
        <filter id="shadow-${type}" x="-50%" y="-50%" width="200%" height="200%">
          <feDropShadow dx="0" dy="2" stdDeviation="2" flood-color="#000" flood-opacity="0.3"/>
        </filter>
        <g filter="url(#shadow-${type})">
          <path 
            d="M24 4C16.268 4 10 10.268 10 18c0 5.922 7.164 15.523 11.36 20.313a3.5 3.5 0 0 0 5.28 0C30.836 33.523 38 23.922 38 18c0-7.732-6.268-14-14-14z" 
            fill="${colors[type]}"
            stroke="white"
            stroke-width="2"
          />
          <circle cx="24" cy="18" r="6" fill="white"/>
        </g>
      </svg>
    `,
    className: "",
    iconSize: [48, 48],
    iconAnchor: [24, 48],
    popupAnchor: [0, -42],
    tooltipAnchor: [0, -36]
  });
};

const MapBoundsUpdater = ({ bounds }) => {
  const map = useMap();
  useEffect(() => {
    if (bounds) {
      map.fitBounds(bounds);
      // Ensure map loads with English labels
      if (map.getContainer()) {
        map.getContainer().style.fontFamily = "'Arial', sans-serif";
      }
    }
  }, [bounds, map]);
  return null;
};


const calculateDistance = (pos1, pos2) => {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(pos2[0] - pos1[0]);
  const dLon = deg2rad(pos2[1] - pos1[1]);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(pos1[0])) *
      Math.cos(deg2rad(pos2[0])) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d.toFixed(2);
};

const deg2rad = (deg) => {
  return deg * (Math.PI / 180);
};

const generateRoute = async (start, end, routeOption) => {
  if (routeOption === 'straightLine') {
    return [start, end];
  } else {
    await new Promise(resolve => setTimeout(resolve, 500));
    return [start, [start[0] + 0.01, start[1] + 0.01], end];
  }
};

const getLineStyle = (travelMode) => {
  const baseStyle = { weight: 4, opacity: 0.8 };
  switch (travelMode?.toLowerCase()) {
    case "walking":
      return { ...baseStyle, color: "#000000", dashArray: "6, 12", weight: 3 };
    case "vehicle":
      return { ...baseStyle, color: "#3b82f6" };
    case "flight":
      return { ...baseStyle, color: "#ef4444", dashArray: "12, 6" };
    default:
      return { ...baseStyle, color: "#6b7280" };
  }
};

const RouteLayer = ({ routeSequence }) => {
  const [routes, setRoutes] = useState([]);

  useEffect(() => {
    const processRoutes = async () => {
      if (!routeSequence || routeSequence.length < 2) return;

      const newRoutes = [];
      
      for (let i = 0; i < routeSequence.length - 1; i++) {
        const start = routeSequence[i];
        const end = routeSequence[i + 1];

        if (!start.position || !end.position) {
          console.warn(`Invalid positions for route ${i}`);
          continue;
        }

        try {
          const routePoints = await generateRoute(
            start.position,
            end.position,
            start.travelMode === 'flight' ? 'straightLine' : (start.routeOption || 'straightLine')
          );

          if (routePoints && routePoints.length > 0) {
            const distance = calculateDistance(start.position, end.position);
            const midpointIndex = Math.floor(routePoints.length / 2);

            newRoutes.push({
              points: routePoints,
              style: getLineStyle(start.travelMode),
              dayNumber: i + 1,
              travelMode: start.travelMode || 'walking',
              distance,
              midpoint: routePoints[midpointIndex],
              routeOption: start.travelMode === 'flight' ? 'straightLine' : (start.routeOption || 'straightLine')
            });
          }
        } catch (error) {
          console.error(`Error processing route ${i}:`, error);
        }
      }

      setRoutes(newRoutes);
    };

    processRoutes();
  }, [routeSequence]);

  return (
    <>
      {routes.map((route, index) => (
        <div key={`route-${index}`}>
          <Polyline
            positions={route.points}
            pathOptions={route.style}
          >
            <Popup>
              <div className="text-sm">
                <strong>Day {route.dayNumber}</strong>
                <div className="capitalize">Mode: {route.travelMode}</div>
                <div>Distance: {route.distance} km</div>
                <div>Route Type: {route.routeOption}</div>
              </div>
            </Popup>
          </Polyline>
          {route.midpoint && (
            <Tooltip 
              permanent 
              direction="center" 
              offset={[0, 0]} 
              position={route.midpoint}
              className="distance-label"
            >
              {route.distance} km
            </Tooltip>
          )}
        </div>
      ))}
    </>
  );
};

const MapView = ({ currentRoute }) => {
  console.log("dataFromMapView :", currentRoute);
  const [mapBounds, setMapBounds] = useState(null);
  const [routeSequence, setRouteSequence] = useState([]);

  useEffect(() => {
    if (!currentRoute) return;

    try {
      const sequence = [];

      if (currentRoute.startingPlace?.coordinates) {
        sequence.push({
          position: [
            currentRoute.startingPlace.coordinates.latitude,
            currentRoute.startingPlace.coordinates.longitude
          ],
          name: currentRoute.startingPlace.name,
          type: "start",
          travelMode: currentRoute.dailyActivities[0]?.travelling,
          routeOption: currentRoute.dailyActivities[0]?.routeOption
        });
      }

      currentRoute.dailyActivities?.forEach((activity, index) => {
        if (activity.endPlace?.coordinates) {
          sequence.push({
            position: [
              activity.endPlace.coordinates.latitude,
              activity.endPlace.coordinates.longitude
            ],
            name: activity.endPlace.name,
            type: "stop",
            travelMode: currentRoute.dailyActivities[index + 1]?.travelling,
            routeOption: activity.routeOption,
            dayNumber: index + 1
          });
        }
      });

      if (currentRoute.endingPlace?.coordinates) {
        sequence.push({
          position: [
            currentRoute.endingPlace.coordinates.latitude,
            currentRoute.endingPlace.coordinates.longitude
          ],
          name: currentRoute.endingPlace.name,
          type: "end"
        });
      }

      if (sequence.length > 0) {
        const points = sequence.map(point => point.position);
        const bounds = L.latLngBounds(points).pad(0.1);
        setMapBounds(bounds);
        setRouteSequence(sequence);
      }
    } catch (error) {
      console.error("Error processing route data:", error);
    }
  }, [currentRoute]);

  if (!currentRoute || !mapBounds || routeSequence.length === 0) {
    return (
      <div className="h-96 w-full rounded-xl bg-gray-100 flex items-center justify-center">
        Loading map...
      </div>
    );
  }

  return (
    <div className="relative h-96 w-full">
      <MapContainer
        center={mapBounds.getCenter()}
        zoom={12}
        className="h-full w-full rounded-xl"
        preferCanvas={true}
        // Add language preference to MapContainer
        whenCreated={(map) => {
          map.getContainer().style.fontFamily = "'Arial', sans-serif";
        }}
      >
        <TileLayer 
          url={TILE_LAYER_URL}
          {...TILE_LAYER_OPTIONS}
        />

<MapBoundsUpdater bounds={mapBounds} />
<RouteLayer routeSequence={routeSequence} />

        {routeSequence.map((point, index) => (
          <Marker
            key={`marker-${index}`}
            position={point.position}
            icon={customIcon(point.type)}
          >
            <Tooltip permanent offset={[0, -36]}>
              {point.name}
            </Tooltip>
            <Popup>
              <div className="text-sm">
                <strong>
                  {point.type === "start" ? "Starting Point" : 
                   point.type === "end" ? "Ending Point" : 
                   `Day ${point.dayNumber}`}
                </strong>
                <div>{point.name}</div>
                {point.travelMode && (
                  <div className="text-xs mt-1 capitalize">
                    Next: {point.travelMode} ➜
                  </div>
                )}
              </div>
            </Popup>
          </Marker>
        ))}
      </MapContainer>

      <div className="absolute bottom-4 right-4 bg-white p-4 rounded-lg shadow-lg z-[1000]">
        <div className="text-sm font-medium mb-3">Travel Mode</div>
        <div className="space-y-3">
          <div className="flex items-center gap-3">
            <div className="w-8 h-1 bg-black border-t-2 border-dashed"></div>
            <span className="text-xs">Walking</span>
          </div>
          <div className="flex items-center gap-3">
            <div className="w-8 h-1 bg-[#3b82f6]"></div>
            <span className="text-xs">Vehicle</span>
          </div>
          <div className="flex items-center gap-3">
            <div className="w-8 h-1 bg-[#ef4444] border-t-2 border-dashed"></div>
            <span className="text-xs">Flight</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapView;