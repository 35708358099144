import { useEffect, useState, useMemo } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap, Tooltip } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

const customIcon = () => {
  return L.divIcon({
    html: `
      <svg width="48" height="48" viewBox="0 0 48 48">
        <filter id="shadow-end" x="-50%" y="-50%" width="200%" height="200%">
          <feDropShadow dx="0" dy="2" stdDeviation="2" flood-color="#000" flood-opacity="0.3"/>
        </filter>
        <g filter="url(#shadow-end)">
          <path
             d="M24 4C16.268 4 10 10.268 10 18c0 5.922 7.164 15.523 11.36 20.313a3.5 3.5 0 0 0 5.28 0C30.836 33.523 38 23.922 38 18c0-7.732-6.268-14-14-14z"
             fill="#ef4444"
            stroke="white"
            stroke-width="2"
          />
          <circle cx="24" cy="18" r="6" fill="white"/>
        </g>
      </svg>
    `,
    className: "",
    iconSize: [48, 48],
    iconAnchor: [24, 48],
    popupAnchor: [0, -42],
    tooltipAnchor: [0, -36]
  });
};

const MapBoundsUpdater = ({ bounds }) => {
  const map = useMap();
  
  useEffect(() => {
    if (bounds) {
      // Add padding to ensure all markers are visible
      map.fitBounds(bounds, {
        padding: [50, 50],
        maxZoom: 8  // Limit max zoom to show more context
      });
    }
  }, [bounds, map]);
  
  return null;
};

const MultipleMapLocations = ({ locations = [] }) => {
  const [mapBounds, setMapBounds] = useState(null);

  const validLocations = useMemo(() => {
    // Filter locations with valid end place coordinates
    const validLocs = locations.filter(loc => 
      loc.endPlace?.coordinates &&
      typeof loc.endPlace.coordinates.latitude === 'number' &&
      typeof loc.endPlace.coordinates.longitude === 'number'
    );

    if (validLocs.length > 0) {
      // Create points array for all valid locations
      const points = validLocs.map(loc => [
        loc.endPlace.coordinates.latitude,
        loc.endPlace.coordinates.longitude
      ]);

      // Calculate bounds with extra padding for better visibility
      const bounds = L.latLngBounds(points).pad(0.5);
      setMapBounds(bounds);
    }

    return validLocs;
  }, [locations]);

  // Calculate initial center point
  const initialCenter = useMemo(() => {
    if (validLocations.length === 0) {
      return [28.3949, 84.1240]; // Default center in Nepal
    }

    // Calculate average of all coordinates
    const totalLat = validLocations.reduce((sum, loc) => 
      sum + loc.endPlace.coordinates.latitude, 0);
    const totalLng = validLocations.reduce((sum, loc) => 
      sum + loc.endPlace.coordinates.longitude, 0);
    
    return [
      totalLat / validLocations.length,
      totalLng / validLocations.length
    ];
  }, [validLocations]);

  if (validLocations.length === 0) {
    return (
      <div className="w-full h-full flex items-center justify-center bg-gray-100 rounded-lg">
        <p className="text-gray-500">No trek destinations available to display</p>
      </div>
    );
  }

  return (
    <div className="w-full h-full relative rounded-lg overflow-hidden shadow-lg">
      <MapContainer
        center={initialCenter}
        zoom={6}  // Start with a wider view
        scrollWheelZoom={true}
        style={{ height: '100%', width: '100%' }}
        className="z-10"
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />

        <MapBoundsUpdater bounds={mapBounds} />
        
        {validLocations.map((trek) => (
          <Marker
            key={trek._id}
            position={[
              trek.endPlace.coordinates.latitude,
              trek.endPlace.coordinates.longitude
            ]}
            icon={customIcon()}
          >
            <Tooltip permanent offset={[0, -36]}>
              {trek.endPlace.name || trek.name}
            </Tooltip>
            <Popup>
              <div className="text-sm">
                <strong>{trek.name}</strong>
                <div>End Point: {trek.endPlace.name}</div>
                <div>Price: ${trek.price}/person</div>
                <div>Duration: {trek.noOfDay} Days</div>
                {trek.features && trek.features.length > 0 && (
                  <div className="text-xs mt-1">
                    {trek.features.slice(0, 3).join(' • ')}
                  </div>
                )}
              </div>
            </Popup>
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
};

export default MultipleMapLocations;