// DatePicker.js
import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { IoIosArrowDown } from "react-icons/io";

const months = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];

const years = Array.from({ length: 100 }, (_, index) => new Date().getFullYear() - index);

const DatePicker = ({ formData, setFormData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const datePickerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const isDateDisabled = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return date < today;
  };

  const handleDateClick = (date) => {
    if (isDateDisabled(date)) return;
    
    setSelectedDate(date);
    
    // Update form data
    setFormData({
      ...formData,
      date: date.toISOString().split('T')[0]
    });

    // Automatically close the picker
    setIsOpen(false);
  };

  const togglePicker = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const renderDateGrid = () => {
    const daysInMonth = new Date(selectedYear, selectedMonth + 1, 0).getDate();
    const firstDayOfMonth = new Date(selectedYear, selectedMonth, 1).getDay();
    const dates = [];

    // Create empty slots for days before the first of the month
    for (let i = 0; i < firstDayOfMonth; i++) {
      dates.push(<div key={`empty-${i}`} className="w-full h-full"></div>);
    }

    // Create slots for each day of the month
    for (let i = 1; i <= daysInMonth; i++) {
      const date = new Date(selectedYear, selectedMonth, i);
      const isDisabled = isDateDisabled(date);
      const isSelected = selectedDate && 
        selectedDate.getDate() === i && 
        selectedDate.getMonth() === selectedMonth;

      dates.push(
        <div
          key={i}
          className={`w-full h-full popp flex items-center justify-center rounded-md py-0.5 
            ${isDisabled ? 'text-gray-300 cursor-not-allowed' : 'cursor-pointer'}
            ${isSelected ? 'bg-[#65b1eb] text-white' : 'text-black hover:bg-gray-200'}
            ${isDisabled && 'hover:bg-transparent'}`}
          onClick={() => !isDisabled && handleDateClick(date)}
        >
          {i}
        </div>
      );
    }

    return dates;
  };

  const handleMonthChange = (e) => {
    setSelectedMonth(Number(e.target.value));
  };

  const handleYearChange = (e) => {
    setSelectedYear(Number(e.target.value));
  };

  const formatDate = (date) => {
    return date
      ? date.toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
        })
      : 'Select Date';
  };

  return (
    <div className="relative w-full" ref={datePickerRef}>
      <div
        className="flex items-center justify-between bg-transparent border-white cursor-pointer mt-2 p-2"
        onClick={togglePicker}
      >
        <span className="popp text-white">
          {selectedDate ? formatDate(selectedDate) : 'Select Date'}
        </span>
        <motion.div
          className="text-white"
          initial={{ rotate: 0 }}
          animate={{ rotate: isOpen ? 180 : 0 }}
        >
          <IoIosArrowDown />
        </motion.div>
      </div>

      {isOpen && (
        <motion.div
          className="absolute popp bg-white p-4 mt-2 rounded-lg shadow-lg z-[999] w-full lg:w-[120%]"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <div className="flex justify-between mb-4">
            <select
              className="appearance-none p-2 px-4 border popp rounded-lg bg-white text-black"
              value={selectedMonth}
              onChange={handleMonthChange}
            >
              {months.map((month, index) => (
                <option className='popp' key={index} value={index}>
                  {month}
                </option>
              ))}
            </select>

            <select
              className="appearance-none p-2 px-4 border popp rounded-lg bg-white text-black"
              value={selectedYear}
              onChange={handleYearChange}
            >
              {years.map((year) => (
                <option className='popp' key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>

          <div className="grid grid-cols-7 text-center text-gray-500 mb-2">
            {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((day) => (
              <div key={day} className="popp w-full">
                {day}
              </div>
            ))}
          </div>

          <div className="grid grid-cols-7 popp gap-2">
            {renderDateGrid()}
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default DatePicker;