import React, { useEffect, useState } from "react";
import { IoAdd, IoRemove } from "react-icons/io5";
import InputField from "../Common/InputField";
import { LuPencil } from "react-icons/lu";
import { LuIndianRupee } from "react-icons/lu";
import { FaRegComments } from "react-icons/fa6";
import { MdOutlineLocationOn } from "react-icons/md";
import ImageKit from "imagekit";
import { toast } from "sonner";
import { HiUpload } from "react-icons/hi";
import { useNavigate, useParams } from "react-router-dom";
import SelectLocation from "./SelectLocation";
import useAuth from "../../services/useAuth";
import { LuHash, LuCalculator, LuHome, LuDollarSign } from "react-icons/lu";

const EditTrek = ({id,setShowEditModal}) => {
  // const { id } = useParams(); 
  const nav = useNavigate();
  const { api } = useAuth();

  const imagekit = new ImageKit({
    publicKey: import.meta.env.VITE_IMAGEKIT_PUBLIC_KEY,
    privateKey: import.meta.env.VITE_IMAGEKIT_PRIVATE_KEY,
    urlEndpoint: import.meta.env.VITE_IMAGEKIT_ENDPOINT,
    transformationPosition: "path",
  });

  // Initial state structure (same as AddTrek)
  const initialTrekDetails = {
    name: "",
    paymentDetails: {
      discount: {
        groupDiscountThreshold: 4,
        groupDiscountPercentage: 20,
        isGroupDiscountApplicable: true,
      },
      charges: {
        gst: 18,
        serviceFee: 5,
        insurancePerPerson: 500,
        bookingFee: 200,
      },
    },
    noOfRoutes: 1,
    startingPlace: {
      name: "",
      coordinates: { latitude: 0, longitude: 0 },
    },
    endingPlace: {
      name: "",
      coordinates: { latitude: 0, longitude: 0 },
    },
    description: "",
    features: [],
    images: {
      bannerImage: "",
      secondaryImages: [],
    },
    routes: [
      {
        name: "",
        pricePerHead: 0,
        numberOfDays: 1,
        numberOfNights: 0,
        description: "",
        dailyActivities: [
          {
            startPlace: {
              name: "",
              coordinates: { latitude: 0, longitude: 0 },
            },
            endPlace: {
              name: "",
              coordinates: { latitude: 0, longitude: 0 },
            },
            travelling: "walking",
            description: "",
            accommodation: {
              title: "",
              images: [],
              description: "",
              features: [],
            },
            foodDetails: {
              breakfast: [""],
              lunch: [""],
              dinner: [""],
            },
          },
        ],
      },
    ],
  };

  const [trekDetails, setTrekDetails] = useState(initialTrekDetails);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);


  // Fetch trek data
  useEffect(() => {
    const fetchTrekData = async () => {
      try {
        setIsLoading(true);
        setError(null);
        const response = await api.get(`/api/treks/${id}`);
        
        if (response.data?.data?.trek) {
          // Get the trek data from the response
          const trekData = response.data.data.trek;
          
          // Create the properly formatted data structure
          const formattedData = {
            name: trekData.name || '',
            paymentDetails: {
              discount: {
                groupDiscountThreshold: trekData.paymentDetails?.discount?.groupDiscountThreshold || 4,
                groupDiscountPercentage: trekData.paymentDetails?.discount?.groupDiscountPercentage || 20,
                isGroupDiscountApplicable: trekData.paymentDetails?.discount?.isGroupDiscountApplicable ?? true,
              },
              charges: {
                gst: trekData.paymentDetails?.charges?.gst || 18,
                serviceFee: trekData.paymentDetails?.charges?.serviceFee || 5,
                insurancePerPerson: trekData.paymentDetails?.charges?.insurancePerPerson || 500,
                bookingFee: trekData.paymentDetails?.charges?.bookingFee || 200,
              },
            },
            noOfRoutes: trekData.noOfRoutes || 1,
            startingPlace: {
              name: trekData.startingPlace?.name || '',
              coordinates: {
                latitude: trekData.startingPlace?.coordinates?.latitude || 0,
                longitude: trekData.startingPlace?.coordinates?.longitude || 0,
              },
            },
            endingPlace: {
              name: trekData.endingPlace?.name || '',
              coordinates: {
                latitude: trekData.endingPlace?.coordinates?.latitude || 0,
                longitude: trekData.endingPlace?.coordinates?.longitude || 0,
              },
            },
            description: trekData.description || '',
            features: trekData.features || [],
            images: {
              bannerImage: trekData.images?.bannerImage || '',
              secondaryImages: trekData.images?.secondaryImages || [],
            },
            routes: trekData.routes?.map(route => ({
              name: route.name || '',
              pricePerHead: route.pricePerHead || 0,
              numberOfDays: route.numberOfDays || 1,
              numberOfNights: route.numberOfNights || 0,
              description: route.description || '',
              dailyActivities: route.dailyActivities?.map(activity => ({
                startPlace: {
                  name: activity.startPlace?.name || '',
                  coordinates: {
                    latitude: activity.startPlace?.coordinates?.latitude || 0,
                    longitude: activity.startPlace?.coordinates?.longitude || 0,
                  },
                },
                endPlace: {
                  name: activity.endPlace?.name || '',
                  coordinates: {
                    latitude: activity.endPlace?.coordinates?.latitude || 0,
                    longitude: activity.endPlace?.coordinates?.longitude || 0,
                  },
                },
                travelling: activity.travelling || 'walking',
                description: activity.description || '',
                accommodation: {
                  title: activity.accommodation?.title || '',
                  images: activity.accommodation?.images || [],
                  description: activity.accommodation?.description || '',
                  features: activity.accommodation?.features || [],
                },
                foodDetails: {
                  breakfast: activity.foodDetails?.breakfast || [''],
                  lunch: activity.foodDetails?.lunch || [''],
                  dinner: activity.foodDetails?.dinner || [''],
                },
              })) || [],
            })) || [],
          };
  
          setTrekDetails(formattedData);
        }
      } catch (error) {
        const errorMessage = error.response?.data?.message || "Failed to fetch trek data";
        setError(errorMessage);
        toast.error(errorMessage);
      } finally {
        setIsLoading(false);
      }
    };
  
    if (id) {
      fetchTrekData();
    }
  }, [id, api]);

  // Basic handlers (same as AddTrek)
  const handleChange = (e) => {
    const { name, value } = e.target;
    setTrekDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // These handlers are referenced but not implemented
  const handleRouteChange = (routeIndex, field, value) => {
    setTrekDetails((prev) => ({
      ...prev,
      routes: prev.routes.map((route, idx) =>
        idx === routeIndex ? { ...route, [field]: value } : route
      ),
    }));
  };

  // This function is referenced but not fully implemented
  const handleAccommodationFeatureChange = (routeIndex, dayIndex, e) => {
    if (e.key === "Enter" && e.target.value.trim()) {
      e.preventDefault();
      const newFeature = e.target.value.trim();
      setTrekDetails((prev) => ({
        ...prev,
        routes: prev.routes.map((route, rIdx) =>
          rIdx === routeIndex
            ? {
                ...route,
                dailyActivities: route.dailyActivities.map((activity, dIdx) =>
                  dIdx === dayIndex
                    ? {
                        ...activity,
                        accommodation: {
                          ...activity.accommodation,
                          features: [
                            ...activity.accommodation.features,
                            newFeature,
                          ],
                        },
                      }
                    : activity
                ),
              }
            : route
        ),
      }));
      e.target.value = "";
    }
  };

  const handleBannerImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    if (!["image/jpeg", "image/png", "image/webp"].includes(file.type)) {
      toast.error("Unsupported file type");
      return;
    }

    if (file.size > 5 * 1024 * 1024) {
      toast.error("File size must be less than 5MB");
      return;
    }

    try {
      const uploadResponse = await imagekit.upload({
        file: file,
        fileName: `trek-banner-${Date.now()}`,
        folder: "/treks/banners",
      });

      setTrekDetails((prev) => ({
        ...prev,
        images: {
          ...prev.images,
          bannerImage: uploadResponse.url,
        },
      }));
    } catch (error) {
      console.error("Banner upload failed:", error);
      toast.error("Failed to upload banner image");
    }
  };

    // Validation functions based on MongoDB schema
    const validateLatitude = (lat) => typeof lat === 'number' && lat >= -90 && lat <= 90;
    const validateLongitude = (lng) => typeof lng === 'number' && lng >= -180 && lng <= 180;
  

  // Add these validations to validateTrekDetails
  const validateTrekDetails = (details) => {
    const errors = [];

    // Basic validations
    if (!details.name?.trim()) errors.push("Trek name is required");
    if (!details.description?.trim()) errors.push("Trek description is required");
    if (!details.images.bannerImage) errors.push("Banner image is required");
    if (details.images.secondaryImages.length < 1) errors.push("At least one secondary image is required");
    if (details.images.secondaryImages.length > 5) errors.push("Maximum 5 secondary images allowed");
    if (details.features.length < 1 || details.features.length > 10) errors.push("Features must be between 1 and 10");

    // Location validations
    if (!details.startingPlace?.name?.trim()) errors.push("Starting place name is required");
    if (!details.endingPlace?.name?.trim()) errors.push("Ending place name is required");
    if (!validateLatitude(details.startingPlace?.coordinates?.latitude)) errors.push("Invalid starting place latitude");
    if (!validateLongitude(details.startingPlace?.coordinates?.longitude)) errors.push("Invalid starting place longitude");
    if (!validateLatitude(details.endingPlace?.coordinates?.latitude)) errors.push("Invalid ending place latitude");
    if (!validateLongitude(details.endingPlace?.coordinates?.longitude)) errors.push("Invalid ending place longitude");

    // Routes validation
    if (details.routes.length !== details.noOfRoutes) {
      errors.push("Number of routes must match noOfRoutes field");
    }

    // Validate each route
    details.routes.forEach((route, index) => {
      if (!route.name?.trim()) errors.push(`Route ${index + 1}: Name is required`);
      if (route.pricePerHead <= 0) errors.push(`Route ${index + 1}: Price must be greater than 0`);
      if (route.numberOfDays < 1) errors.push(`Route ${index + 1}: Number of days must be at least 1`);
      if (route.numberOfNights < 0) errors.push(`Route ${index + 1}: Number of nights cannot be negative`);
      if (!route.description?.trim()) errors.push(`Route ${index + 1}: Description is required`);
      
      // Validate daily activities match number of days
      if (route.dailyActivities.length !== route.numberOfDays) {
        errors.push(`Route ${index + 1}: Number of daily activities must match number of days`);
      }

      // Validate each daily activity
      route.dailyActivities.forEach((activity, dayIndex) => {
        // Validate food details
        ['breakfast', 'lunch', 'dinner'].forEach(meal => {
          if (!activity.foodDetails[meal] || activity.foodDetails[meal].length < 1) {
            errors.push(`Route ${index + 1}, Day ${dayIndex + 1}: At least one ${meal} item is required`);
          }
          if (activity.foodDetails[meal].length > 10) {
            errors.push(`Route ${index + 1}, Day ${dayIndex + 1}: Maximum 10 ${meal} items allowed`);
          }
        });

        // Validate accommodation
        if (!activity.accommodation.title?.trim()) {
          errors.push(`Route ${index + 1}, Day ${dayIndex + 1}: Accommodation title is required`);
        }
        if (!activity.accommodation.description?.trim()) {
          errors.push(`Route ${index + 1}, Day ${dayIndex + 1}: Accommodation description is required`);
        }
        if (activity.accommodation.images.length === 0) {
          errors.push(`Route ${index + 1}, Day ${dayIndex + 1}: At least one accommodation image is required`);
        }
      });
    });

    return errors;
  };

  // Add handlers for feature management
  const handleFeaturesChange = (e) => {
    if (e.key === "Enter" && e.target.value.trim()) {
      e.preventDefault();
      const newFeature = e.target.value.trim();
      if (!trekDetails.features.includes(newFeature)) {
        setTrekDetails((prev) => ({
          ...prev,
          features: [...prev.features, newFeature],
        }));
      }
      e.target.value = "";
    }
  };

  const removeFeature = (feature) => {
    setTrekDetails((prev) => ({
      ...prev,
      features: prev.features.filter((f) => f !== feature),
    }));
  };

  // Add handlers for daily activities
  const handleDailyActivityChange = (routeIndex, dayIndex, field, value) => {
    setTrekDetails((prev) => ({
      ...prev,
      routes: prev.routes.map((route, rIndex) => {
        if (rIndex !== routeIndex) return route;
        return {
          ...route,
          dailyActivities: route.dailyActivities.map((activity, dIndex) => {
            if (dIndex !== dayIndex) return activity;
            return {
              ...activity,
              [field]: value,
            };
          }),
        };
      }),
    }));
  };


  // Image upload handler with better validation and error handling
  const handleImageUpload = async (file, folder, errorCallback) => {
    try {
      if (!file) throw new Error("No file selected");

      // Validate file type
      if (!["image/jpeg", "image/png", "image/webp"].includes(file.type)) {
        throw new Error("Unsupported file type. Please use JPEG, PNG, or WebP");
      }

      // Validate file size (5MB)
      if (file.size > 5 * 1024 * 1024) {
        throw new Error("File size must be less than 5MB");
      }

      const uploadResponse = await imagekit.upload({
        file,
        fileName: `trek-${Date.now()}-${file.name}`,
        folder,
      });

      return uploadResponse.url;
    } catch (error) {
      console.error("Image upload failed:", error);
      errorCallback(error.message);
      return null;
    }
  };

  // Add this handler for secondary images upload
const handleSecondaryImagesUpload = async (e) => {
  const files = Array.from(e.target.files);
  if (files.length === 0) return;

  try {
    const uploadPromises = files.map(async (file) => {
      // Validate file type
      if (!["image/jpeg", "image/png", "image/webp"].includes(file.type)) {
        throw new Error(`Unsupported file type: ${file.type}`);
      }

      // Validate file size (5MB)
      if (file.size > 5 * 1024 * 1024) {
        throw new Error("File size must be less than 5MB");
      }

      const uploadResponse = await imagekit.upload({
        file: file,
        fileName: `trek-secondary-${Date.now()}-${file.name}`,
        folder: "/treks/secondary",
      });

      return uploadResponse.url;
    });

    const uploadedUrls = await Promise.all(uploadPromises);

    setTrekDetails((prev) => ({
      ...prev,
      images: {
        ...prev.images,
        secondaryImages: [...prev.images.secondaryImages, ...uploadedUrls],
      },
    }));
  } catch (error) {
    console.error("Secondary image upload failed:", error);
    toast.error(error.message || "Failed to upload secondary images");
  }
};


  // Add route management handlers
  const addRoute = () => {
    setTrekDetails((prev) => ({
      ...prev,
      routes: [
        ...prev.routes,
        {
          name: "",
          pricePerHead: 0,
          numberOfDays: 1,
          numberOfNights: 0,
          description: "",
          dailyActivities: [
            {
              startPlace: {
                name: "",
                coordinates: { latitude: 0, longitude: 0 },
              },
              endPlace: {
                name: "",
                coordinates: { latitude: 0, longitude: 0 },
              },
              travelling: "walking",
              description: "",
              accommodation: {
                title: "",
                images: [],
                description: "",
                features: [],
              },
              foodDetails: {
                breakfast: [""],
                lunch: [""],
                dinner: [""],
              },
            },
          ],
        },
      ],
    }));
  };

  const removeRoute = (index) => {
    if (window.confirm("Are you sure you want to remove this route?")) {
      setTrekDetails((prev) => ({
        ...prev,
        routes: prev.routes.filter((_, i) => i !== index),
      }));
    }
  };

  const addFoodItem = (routeIndex, dayIndex, mealType) => {
    setTrekDetails((prev) => ({
      ...prev,
      routes: prev.routes.map((route, rIdx) =>
        rIdx === routeIndex
          ? {
              ...route,
              dailyActivities: route.dailyActivities.map((activity, dIdx) =>
                dIdx === dayIndex
                  ? {
                      ...activity,
                      foodDetails: {
                        ...activity.foodDetails,
                        [mealType]: [...activity.foodDetails[mealType], ""],
                      },
                    }
                  : activity
              ),
            }
          : route
      ),
    }));
  };

  const handleFoodItemChange = (
    routeIndex,
    dayIndex,
    mealType,
    itemIndex,
    value
  ) => {
    setTrekDetails((prev) => ({
      ...prev,
      routes: prev.routes.map((route, rIdx) =>
        rIdx === routeIndex
          ? {
              ...route,
              dailyActivities: route.dailyActivities.map((activity, dIdx) =>
                dIdx === dayIndex
                  ? {
                      ...activity,
                      foodDetails: {
                        ...activity.foodDetails,
                        [mealType]: activity.foodDetails[mealType].map(
                          (item, iIdx) => (iIdx === itemIndex ? value : item)
                        ),
                      },
                    }
                  : activity
              ),
            }
          : route
      ),
    }));
  };

  // These functions are referenced but missing
  const removeAccommodationFeature = (routeIndex, dayIndex, feature) => {
    setTrekDetails((prev) => ({
      ...prev,
      routes: prev.routes.map((route, rIdx) =>
        rIdx === routeIndex
          ? {
              ...route,
              dailyActivities: route.dailyActivities.map((activity, dIdx) =>
                dIdx === dayIndex
                  ? {
                      ...activity,
                      accommodation: {
                        ...activity.accommodation,
                        features: activity.accommodation.features.filter(
                          (f) => f !== feature
                        ),
                      },
                    }
                  : activity
              ),
            }
          : route
      ),
    }));
  };

  const removeFoodItem = (routeIndex, dayIndex, mealType, itemIndex) => {
    setTrekDetails((prev) => ({
      ...prev,
      routes: prev.routes.map((route, rIdx) =>
        rIdx === routeIndex
          ? {
              ...route,
              dailyActivities: route.dailyActivities.map((activity, dIdx) =>
                dIdx === dayIndex
                  ? {
                      ...activity,
                      foodDetails: {
                        ...activity.foodDetails,
                        [mealType]: activity.foodDetails[mealType].filter(
                          (_, idx) => idx !== itemIndex
                        ),
                      },
                    }
                  : activity
              ),
            }
          : route
      ),
    }));
  };

  const handleAccommodationImageUpload = async (routeIndex, dayIndex, e) => {
    const files = Array.from(e.target.files);
    if (files.length === 0) return;

    try {
      const uploadPromises = files.map(async (file) => {
        if (!["image/jpeg", "image/png", "image/webp"].includes(file.type)) {
          throw new Error(`Unsupported file type: ${file.type}`);
        }
        if (file.size > 5 * 1024 * 1024) {
          throw new Error("File size must be less than 5MB");
        }

        const uploadResponse = await imagekit.upload({
          file: file,
          fileName: `accommodation-${Date.now()}-${file.name}`,
          folder: "/treks/accommodations",
        });

        return uploadResponse.url;
      });

      const uploadedUrls = await Promise.all(uploadPromises);

      setTrekDetails((prev) => ({
        ...prev,
        routes: prev.routes.map((route, rIdx) =>
          rIdx === routeIndex
            ? {
                ...route,
                dailyActivities: route.dailyActivities.map((activity, dIdx) =>
                  dIdx === dayIndex
                    ? {
                        ...activity,
                        accommodation: {
                          ...activity.accommodation,
                          images: [
                            ...activity.accommodation.images,
                            ...uploadedUrls,
                          ],
                        },
                      }
                    : activity
                ),
              }
            : route
        ),
      }));
    } catch (error) {
      console.error("Accommodation image upload failed:", error);
      toast.error(error.message || "Failed to upload accommodation images");
    }
  };

  // Modified handleSubmit for editing
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (isSubmitting) return;
  
    let loadingToast;
  
    try {
      setIsSubmitting(true);
      
      // Validate trek details
      const validationErrors = validateTrekDetails(trekDetails);
      if (validationErrors.length > 0) {
        validationErrors.forEach(error => toast.error(error));
        setIsSubmitting(false);
        return;
      }
  
      loadingToast = toast.loading("Updating trek...");

      const trekDetail = {
        name: trekDetails.name,
        paymentDetails: trekDetails.paymentDetails,
        noOfRoutes: Number(trekDetails.noOfRoutes),
        startingPlace: trekDetails.startingPlace,
        endingPlace: trekDetails.endingPlace,
        description: trekDetails.description,
        features: trekDetails.features,
        images: trekDetails.images,
        routes: trekDetails.routes.map(route => ({
          name: route.name,
          pricePerHead: Number(route.pricePerHead),
          numberOfDays: Number(route.numberOfDays),
          numberOfNights: Number(route.numberOfNights),
          description: route.description,
          dailyActivities: route.dailyActivities.map(activity => ({
            startPlace: {
              name: activity.startPlace.name,
              coordinates: {
                latitude: Number(activity.startPlace.coordinates.latitude),
                longitude: Number(activity.startPlace.coordinates.longitude)
              }
            },
            endPlace: {
              name: activity.endPlace.name,
              coordinates: {
                latitude: Number(activity.endPlace.coordinates.latitude),
                longitude: Number(activity.endPlace.coordinates.longitude)
              }
            },
            travelling: activity.travelling,
            description: activity.description,
            accommodation: {
              title: activity.accommodation.title,
              images: activity.accommodation.images,
              description: activity.accommodation.description,
              features: activity.accommodation.features || []
            },
            foodDetails: {
              breakfast: activity.foodDetails.breakfast.filter(item => item.trim()),
              lunch: activity.foodDetails.lunch.filter(item => item.trim()),
              dinner: activity.foodDetails.dinner.filter(item => item.trim())
            }
          }))
        }))
      }

      console.log("trekDetail : ",trekDetail);
  
      // Send the trekDetails directly since it's already properly structured
      const response = await api.patch(`/api/treks/${id}`,trekDetail );
  
      if (response.data) {
        toast.success("Trek updated successfully!");
        
        // Close modal if it exists
        if (setShowEditModal) {
          setShowEditModal(false);
        }
        
        // Navigate after a short delay
        setTimeout(() => nav("/packages"), 1500);
      } else {
        throw new Error("No data received from server");
      }
  
    } catch (error) {
      let errorMessage = "Failed to update trek";
  
      if (error.response) {
        switch (error.response.status) {
          case 400:
            errorMessage = error.response.data?.message || "Invalid trek details. Please check your input.";
            break;
          case 401:
            errorMessage = "You are not authorized to edit this trek.";
            nav('/login');
            break;
          case 404:
            errorMessage = "Trek not found.";
            break;
          case 413:
            errorMessage = "Image size too large. Please use smaller images.";
            break;
          case 422:
            if (error.response.data?.errors) {
              error.response.data.errors.forEach((err) => {
                toast.error(err.toString());
              });
              return;
            }
            errorMessage = "Validation failed. Please check your input.";
            break;
          default:
            errorMessage = error.response.data?.message || "An error occurred while updating the trek.";
        }
      } else if (error.request) {
        errorMessage = "No response from server. Please check your internet connection.";
      }
  
      console.error("Error updating trek:", error);
      toast.error(errorMessage);
    } finally {
      setIsSubmitting(false);
      if (loadingToast) {
        toast.dismiss(loadingToast);
      }
    }
  };

  // Add better error and loading handling
  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="text-red-500 text-center">
          <h3 className="text-xl font-semibold mb-2">Error Loading Trek</h3>
          <p>{error}</p>
          <button
            onClick={() => window.location.reload()}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full md:px-[50px] lg:p-4 mx-auto max-w-5xl bg-white rounded-lg">
      <h2 className="text-2xl font-semibold mb-5">Edit Trek</h2>
      <div className="space-y-4">
        {/* Basic Trek Information */}
        <InputField
          icon={LuPencil}
          name="name"
          type="text"
          title="Trek Name"
          value={trekDetails.name}
          handleChange={handleChange}
        />

        {/* Description */}
        <div>
          <label className="block mb-1">Description</label>
          <textarea
            name="description"
            value={trekDetails.description}
            onChange={handleChange}
            placeholder="Enter the description"
            className="w-full p-3 border border-gray-300 rounded-[10px] focus:outline-none focus:ring focus:ring-blue-300"
          />
        </div>

        {/* Features */}
        <div>
          <label className="block mb-1">Features</label>
          <div className="mb-2 flex flex-wrap gap-2">
            {trekDetails.features.map((feature, index) => (
              <div
                key={index}
                className="flex items-center bg-zinc-200 rounded-[10px] px-3 py-1.5"
              >
                <span>{feature}</span>
                <button
                  type="button"
                  onClick={() => removeFeature(feature)}
                  className="ml-2 text-red-500"
                >
                  &times;
                </button>
              </div>
            ))}
          </div>
          <input
            type="text"
            placeholder="Add feature and press Enter"
            onKeyDown={handleFeaturesChange}
            className="w-full p-3 border border-gray-300 rounded-[10px] focus:outline-none focus:ring focus:ring-blue-300"
          />
        </div>

        {/* Trek Starting Place */}
        <div className="border p-4 rounded-[10px]">
          <h3 className="text-lg mb-4"> Trek Starting Place</h3>
          <div className="space-y-4">
            <InputField
              icon={MdOutlineLocationOn}
              name="startingPlace.name"
              type="text"
              title="Starting Place Name"
              value={trekDetails.startingPlace.name}
              handleChange={(e) =>
                setTrekDetails((prev) => ({
                  ...prev,
                  startingPlace: {
                    ...prev.startingPlace,
                    name: e.target.value,
                  },
                }))
              }
            />
            <div className="grid grid-cols-2 gap-4">
              <InputField
                icon={LuHash}
                type="number"
                title="Latitude"
                value={trekDetails.startingPlace.coordinates.latitude}
                handleChange={(e) =>
                  setTrekDetails((prev) => ({
                    ...prev,
                    startingPlace: {
                      ...prev.startingPlace,
                      coordinates: {
                        ...prev.startingPlace.coordinates,
                        latitude: parseFloat(e.target.value),
                      },
                    },
                  }))
                }
              />
              <InputField
                // Add icon prop here
                icon={LuHash}
                type="number"
                title="Longitude"
                value={trekDetails.startingPlace.coordinates.longitude}
                handleChange={(e) =>
                  setTrekDetails((prev) => ({
                    ...prev,
                    startingPlace: {
                      ...prev.startingPlace,
                      coordinates: {
                        ...prev.startingPlace.coordinates,
                        longitude: parseFloat(e.target.value),
                      },
                    },
                  }))
                }
              />
            </div>
          </div>
        </div>

        {/* Ending Place */}
        <div className="border p-4 rounded-[10px]">
          <h3 className="text-lg mb-4"> Trek Ending Place</h3>
          <div className="space-y-4">
            <InputField
              icon={MdOutlineLocationOn}
              name="endingPlace.name"
              type="text"
              title="Ending Place Name"
              value={trekDetails.endingPlace.name}
              handleChange={(e) =>
                setTrekDetails((prev) => ({
                  ...prev,
                  endingPlace: {
                    ...prev.endingPlace,
                    name: e.target.value,
                  },
                }))
              }
            />
            <div className="grid grid-cols-2 gap-4">
              <InputField
                icon={LuHash}
                type="number"
                title="Latitude"
                value={trekDetails.endingPlace.coordinates.latitude}
                handleChange={(e) =>
                  setTrekDetails((prev) => ({
                    ...prev,
                    endingPlace: {
                      ...prev.endingPlace,
                      coordinates: {
                        ...prev.endingPlace.coordinates,
                        latitude: parseFloat(e.target.value),
                      },
                    },
                  }))
                }
              />
              <InputField
                icon={LuHash}
                type="number"
                title="Longitude"
                value={trekDetails.endingPlace.coordinates.longitude}
                handleChange={(e) =>
                  setTrekDetails((prev) => ({
                    ...prev,
                    endingPlace: {
                      ...prev.endingPlace,
                      coordinates: {
                        ...prev.endingPlace.coordinates,
                        longitude: parseFloat(e.target.value),
                      },
                    },
                  }))
                }
              />
            </div>
          </div>
        </div>

        {/* Routes Section */}
        <div className="space-y-4">
          <div className="flex justify-between items-center">
            <h3 className="text-xl font-semibold">Routes</h3>
            <button
              type="button"
              onClick={addRoute}
              className="p-2 bg-[#70B4E8] text-white rounded-[10px]"
            >
              Add Route
            </button>
          </div>

          {trekDetails.routes.map((route, routeIndex) => (
            <div
              key={routeIndex}
              className="p-4 border rounded-[10px] space-y-4"
            >
              <div className="flex justify-between items-center mb-4">
                <h4 className="text-lg font-medium">Route {routeIndex + 1}</h4>
                {routeIndex > 0 && (
                  <button
                    type="button"
                    onClick={() => removeRoute(routeIndex)}
                    className="text-red-500"
                  >
                    Remove Route
                  </button>
                )}
              </div>

              {/* Route Basic Details */}
              <div className="space-y-4">
                <InputField
                  icon={LuPencil}
                  name="name"
                  type="text"
                  title="Route Name"
                  value={route.name}
                  handleChange={(e) =>
                    handleRouteChange(routeIndex, "name", e.target.value)
                  }
                />

                <InputField
                  icon={LuDollarSign}
                  name="pricePerHead"
                  type="number"
                  title="Price Per Head"
                  value={route.pricePerHead}
                  handleChange={(e) =>
                    handleRouteChange(
                      routeIndex,
                      "pricePerHead",
                      e.target.value
                    )
                  }
                />

                <div className="grid grid-cols-2 gap-4">
                  <InputField
                    icon={LuCalculator}
                    name="numberOfDays"
                    type="number"
                    title="Number of Days"
                    value={route.numberOfDays}
                    handleChange={(e) =>
                      handleRouteChange(
                        routeIndex,
                        "numberOfDays",
                        e.target.value
                      )
                    }
                  />
                  <InputField
                    icon={LuCalculator}
                    name="numberOfNights"
                    type="number"
                    title="Number of Nights"
                    value={route.numberOfNights}
                    handleChange={(e) =>
                      handleRouteChange(
                        routeIndex,
                        "numberOfNights",
                        e.target.value
                      )
                    }
                  />
                </div>

                <textarea
                  placeholder="Route Description"
                  value={route.description}
                  onChange={(e) =>
                    handleRouteChange(routeIndex, "description", e.target.value)
                  }
                  className="w-full p-3 border border-gray-300 rounded-[10px]"
                />
              </div>

              {/* Daily Activities for this route */}
              <div className="mt-6">
                <h5 className="text-lg font-medium mb-4">Daily Activities</h5>
                {route.dailyActivities.map((activity, dayIndex) => (
                  <div
                    key={dayIndex}
                    className="mb-6 p-4 bg-gray-50 rounded-[10px] space-y-4"
                  >
                    <h6 className="font-medium">Day {dayIndex + 1}</h6>

                    {/* Inside the daily activities mapping */}
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <InputField
                          icon={MdOutlineLocationOn}
                          title="Start Place Name"
                          value={activity.startPlace.name}
                          handleChange={(e) =>
                            handleDailyActivityChange(
                              routeIndex,
                              dayIndex,
                              "startPlace",
                              {
                                ...activity.startPlace,
                                name: e.target.value,
                              }
                            )
                          }
                        />
                        <div className="grid grid-cols-2 gap-2 mt-2">
                          <InputField
                            icon={LuHash}
                            title="Latitude"
                            type="number"
                            value={activity.startPlace.coordinates.latitude}
                            handleChange={(e) =>
                              handleDailyActivityChange(
                                routeIndex,
                                dayIndex,
                                "startPlace",
                                {
                                  ...activity.startPlace,
                                  coordinates: {
                                    ...activity.startPlace.coordinates,
                                    latitude: parseFloat(e.target.value),
                                  },
                                }
                              )
                            }
                          />
                          <InputField
                            icon={LuHash}
                            title="Longitude"
                            type="number"
                            value={activity.startPlace.coordinates.longitude}
                            handleChange={(e) =>
                              handleDailyActivityChange(
                                routeIndex,
                                dayIndex,
                                "startPlace",
                                {
                                  ...activity.startPlace,
                                  coordinates: {
                                    ...activity.startPlace.coordinates,
                                    longitude: parseFloat(e.target.value),
                                  },
                                }
                              )
                            }
                          />
                        </div>
                      </div>

                      <div>
                        <InputField
                          icon={MdOutlineLocationOn}
                          title="End Place Name"
                          value={activity.endPlace.name}
                          handleChange={(e) =>
                            handleDailyActivityChange(
                              routeIndex,
                              dayIndex,
                              "endPlace",
                              {
                                ...activity.endPlace,
                                name: e.target.value,
                              }
                            )
                          }
                        />
                        <div className="grid grid-cols-2 gap-2 mt-2">
                          <InputField
                            icon={LuHash}
                            title="Latitude"
                            type="number"
                            value={activity.endPlace.coordinates.latitude}
                            handleChange={(e) =>
                              handleDailyActivityChange(
                                routeIndex,
                                dayIndex,
                                "endPlace",
                                {
                                  ...activity.endPlace,
                                  coordinates: {
                                    ...activity.endPlace.coordinates,
                                    latitude: parseFloat(e.target.value),
                                  },
                                }
                              )
                            }
                          />
                          <InputField
                            icon={LuHash}
                            title="Longitude"
                            type="number"
                            value={activity.endPlace.coordinates.longitude}
                            handleChange={(e) =>
                              handleDailyActivityChange(
                                routeIndex,
                                dayIndex,
                                "endPlace",
                                {
                                  ...activity.endPlace,
                                  coordinates: {
                                    ...activity.endPlace.coordinates,
                                    longitude: parseFloat(e.target.value),
                                  },
                                }
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <select
                      value={activity.travelling}
                      onChange={(e) =>
                        handleDailyActivityChange(
                          routeIndex,
                          dayIndex,
                          "travelling",
                          e.target.value
                        )
                      }
                      className="w-full p-3 border border-gray-300 rounded-[10px]"
                    >
                      <option value="walking">Walking</option>
                      <option value="vehicle">Vehicle</option>
                      <option value="flight">Flight</option>
                    </select>

                    <textarea
                      placeholder="Day Description"
                      value={activity.description}
                      onChange={(e) =>
                        handleDailyActivityChange(
                          routeIndex,
                          dayIndex,
                          "description",
                          e.target.value
                        )
                      }
                      className="w-full p-3 border border-gray-300 rounded-[10px]"
                    />

                    {/* Accommodation Section */}
                    <div className="space-y-4 border-t pt-4">
                      <h6 className="font-medium">Accommodation</h6>
                      <InputField
                        icon={LuHome}
                        title="Accommodation Title"
                        value={activity.accommodation.title}
                        handleChange={(e) =>
                          handleDailyActivityChange(
                            routeIndex,
                            dayIndex,
                            "accommodation",
                            {
                              ...activity.accommodation,
                              title: e.target.value,
                            }
                          )
                        }
                      />
                      <textarea
                        placeholder="Accommodation Description"
                        value={activity.accommodation.description}
                        onChange={(e) =>
                          handleDailyActivityChange(
                            routeIndex,
                            dayIndex,
                            "accommodation",
                            {
                              ...activity.accommodation,
                              description: e.target.value,
                            }
                          )
                        }
                        className="w-full p-3 border border-gray-300 rounded-[10px]"
                      />

                      {/* Accommodation Features */}
                      <div>
                        <label className="block mb-1">
                          Accommodation Features
                        </label>
                        <div className="mb-2 flex flex-wrap gap-2">
                          {activity.accommodation.features.map(
                            (feature, featureIndex) => (
                              <div
                                key={featureIndex}
                                className="flex items-center bg-zinc-200 rounded-[10px] px-3 py-1.5"
                              >
                                <span>{feature}</span>
                                <button
                                  type="button"
                                  onClick={() =>
                                    removeAccommodationFeature(
                                      routeIndex,
                                      dayIndex,
                                      feature
                                    )
                                  }
                                  className="ml-2 text-red-500"
                                >
                                  ×
                                </button>
                              </div>
                            )
                          )}
                        </div>
                        <input
                          type="text"
                          placeholder="Add accommodation feature and press Enter"
                          onKeyDown={(e) =>
                            handleAccommodationFeatureChange(
                              routeIndex,
                              dayIndex,
                              e
                            )
                          }
                          className="w-full p-3 border border-gray-300 rounded-[10px]"
                        />
                      </div>

                      {/* Accommodation Images */}
                      <div>
                        <label className="block mb-1">
                          Accommodation Images
                        </label>
                        <div className="relative">
                          <input
                            type="file"
                            accept="image/*"
                            onChange={(e) =>
                              handleAccommodationImageUpload(
                                routeIndex,
                                dayIndex,
                                e
                              )
                            }
                            multiple
                            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                          />
                          <div className="flex items-center justify-center border border-gray-300 rounded-[10px] p-3 bg-gray-50 hover:bg-gray-100">
                            <HiUpload className="text-gray-500 mr-2" />
                            <span>Upload Accommodation Images</span>
                          </div>
                        </div>
                        <div className="mt-4 flex flex-wrap gap-4">
                          {activity.accommodation.images.map(
                            (image, imageIndex) => (
                              <div key={imageIndex} className="relative">
                                <img
                                  src={image}
                                  alt={`Accommodation ${imageIndex + 1}`}
                                  className="w-24 h-24 object-cover rounded-[10px]"
                                />
                                <button
                                  type="button"
                                  onClick={() => {
                                    // Handle image removal
                                    setTrekDetails((prev) => ({
                                      ...prev,
                                      routes: prev.routes.map((r, ri) =>
                                        ri === routeIndex
                                          ? {
                                              ...r,
                                              dailyActivities:
                                                r.dailyActivities.map((a, di) =>
                                                  di === dayIndex
                                                    ? {
                                                        ...a,
                                                        accommodation: {
                                                          ...a.accommodation,
                                                          images:
                                                            a.accommodation.images.filter(
                                                              (_, i) =>
                                                                i !== imageIndex
                                                            ),
                                                        },
                                                      }
                                                    : a
                                                ),
                                            }
                                          : r
                                      ),
                                    }));
                                  }}
                                  className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center"
                                >
                                  ×
                                </button>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>

                    {/* Food Details Section */}
                    <div className="space-y-4 border-t pt-4">
                      <h6 className="font-medium">Food Details</h6>
                      {["breakfast", "lunch", "dinner"].map((mealType) => (
                        <div key={mealType} className="space-y-2">
                          <div className="flex items-center justify-between">
                            <label className="capitalize">{mealType}</label>
                            <button
                              type="button"
                              onClick={() =>
                                addFoodItem(routeIndex, dayIndex, mealType)
                              }
                              disabled={
                                activity.foodDetails[mealType].length >= 10
                              }
                              className="text-sm px-2 py-1 bg-[#70B4E8] text-white rounded-[10px] disabled:opacity-50"
                            >
                              Add Item
                            </button>
                          </div>
                          <div className="space-y-2">
                            {activity.foodDetails[mealType].map(
                              (item, itemIndex) => (
                                <div key={itemIndex} className="flex gap-2">
                                  <input
                                    type="text"
                                    placeholder={`${mealType} item ${
                                      itemIndex + 1
                                    }`}
                                    value={item}
                                    onChange={(e) =>
                                      handleFoodItemChange(
                                        routeIndex,
                                        dayIndex,
                                        mealType,
                                        itemIndex,
                                        e.target.value
                                      )
                                    }
                                    className="flex-1 p-2 border border-gray-300 rounded-[10px]"
                                  />
                                  {activity.foodDetails[mealType].length >
                                    1 && (
                                    <button
                                      type="button"
                                      onClick={() =>
                                        removeFoodItem(
                                          routeIndex,
                                          dayIndex,
                                          mealType,
                                          itemIndex
                                        )
                                      }
                                      className="px-2 py-1 text-red-500 rounded-[10px] hover:bg-red-50"
                                    >
                                      ×
                                    </button>
                                  )}
                                </div>
                              )
                            )}
                          </div>
                          <p className="text-sm text-gray-500">
                            {10 - activity.foodDetails[mealType].length} more
                            items can be added
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        {/* Images Section */}
        <div className="flex flex-col gap-1">
          <label className="block mb-1">Upload Banner Image</label>
          <div className="relative">
            <input
              type="file"
              accept="image/*"
              onChange={handleBannerImageUpload}
              className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
              required
            />
            <div className="flex flex-col gap-4 md:flex-row items-center justify-center border border-gray-300 rounded-[10px] p-3 bg-gray-50 hover:bg-gray-100 transition duration-200">
              <div className="flex items-center">
                <HiUpload className="text-gray-500 mr-2 inline" />
                <span className="text-gray-700">Select a Banner Image</span>
              </div>
              <div className="block">Maximum image size is 5MB</div>
            </div>
          </div>
        </div>

        <div className="mt-4 mb-2 rounded-[10px]">
          {trekDetails.images.bannerImage && (
            <img
              src={trekDetails.images.bannerImage}
              alt="Banner"
              className="w-96 border p-2 object-cover rounded-[10px]"
            />
          )}
        </div>

        {/* Secondary Images */}
        <div className="flex flex-col gap-1">
          <label className="block mb-1">Upload Secondary Images</label>
          <div className="relative">
            <input
              type="file"
              accept="image/*"
              onChange={handleSecondaryImagesUpload}
              className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
              multiple
            />
            <div className="flex items-center justify-center border border-gray-300 rounded-[10px] p-3 bg-gray-50 hover:bg-gray-100 transition duration-200">
              <HiUpload className="text-gray-500 mr-2 text-2xl" />
              <span className="text-gray-700">
                Select Multiple Secondary Images
              </span>
            </div>
          </div>
        </div>

        <div className="mt-4 flex flex-wrap gap-4">
          {trekDetails.images.secondaryImages.map((image, index) => (
            <div key={index} className="relative">
              <img
                src={image}
                alt={`Secondary ${index + 1}`}
                className="w-56 h-56 border p-2 object-cover rounded-[10px]"
              />
              <button
                type="button"
                onClick={() => {
                  setTrekDetails((prev) => ({
                    ...prev,
                    images: {
                      ...prev.images,
                      secondaryImages: prev.images.secondaryImages.filter(
                        (_, i) => i !== index
                      ),
                    },
                  }));
                }}
                className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center"
              >
                &times;
              </button>
            </div>
          ))}
        </div>

        {/* Payment Details */}
        <div className="border p-4 rounded-[10px]">
          <h3 className="text-lg font-semibold mb-4">Payment Details</h3>

          {/* Discount Settings */}
          <div className="space-y-4 mb-6">
            <h4 className="font-medium">Group Discount Settings</h4>
            <div className="grid grid-cols-2 gap-4">
              <InputField
                icon={LuCalculator} // Add this
                type="number"
                title="Group Discount Threshold"
                value={
                  trekDetails.paymentDetails.discount.groupDiscountThreshold
                }
                handleChange={(e) =>
                  setTrekDetails((prev) => ({
                    ...prev,
                    paymentDetails: {
                      ...prev.paymentDetails,
                      discount: {
                        ...prev.paymentDetails.discount,
                        groupDiscountThreshold: parseInt(e.target.value),
                      },
                    },
                  }))
                }
              />
              <InputField
                icon={LuCalculator} // Add this
                type="number"
                title="Group Discount Percentage"
                value={
                  trekDetails.paymentDetails.discount.groupDiscountPercentage
                }
                handleChange={(e) =>
                  setTrekDetails((prev) => ({
                    ...prev,
                    paymentDetails: {
                      ...prev.paymentDetails,
                      discount: {
                        ...prev.paymentDetails.discount,
                        groupDiscountPercentage: parseInt(e.target.value),
                      },
                    },
                  }))
                }
              />
            </div>
            <div className="flex items-center">
              <input
                type="checkbox"
                checked={
                  trekDetails.paymentDetails.discount.isGroupDiscountApplicable
                }
                onChange={(e) =>
                  setTrekDetails((prev) => ({
                    ...prev,
                    paymentDetails: {
                      ...prev.paymentDetails,
                      discount: {
                        ...prev.paymentDetails.discount,
                        isGroupDiscountApplicable: e.target.checked,
                      },
                    },
                  }))
                }
                className="mr-2"
              />
              <label>Enable Group Discount</label>
            </div>
          </div>

          {/* Charges */}
          <div className="space-y-4">
            <h4 className="font-medium">Charges</h4>
            <div className="grid grid-cols-2 gap-4">
              <InputField
                icon={LuCalculator}
                type="number"
                title="GST (%)"
                value={trekDetails.paymentDetails.charges.gst}
                handleChange={(e) =>
                  setTrekDetails((prev) => ({
                    ...prev,
                    paymentDetails: {
                      ...prev.paymentDetails,
                      charges: {
                        ...prev.paymentDetails.charges,
                        gst: parseInt(e.target.value),
                      },
                    },
                  }))
                }
              />
              <InputField
                icon={LuCalculator}
                type="number"
                title="Service Fee (%)"
                value={trekDetails.paymentDetails.charges.serviceFee}
                handleChange={(e) =>
                  setTrekDetails((prev) => ({
                    ...prev,
                    paymentDetails: {
                      ...prev.paymentDetails,
                      charges: {
                        ...prev.paymentDetails.charges,
                        serviceFee: parseInt(e.target.value),
                      },
                    },
                  }))
                }
              />
            </div>
          </div>
        </div>

        <button
          onClick={handleSubmit}
          className="w-full p-3 bg-[#4997D3] text-white rounded-[10px] hover:bg-[#4fabf1] focus:ring focus:ring-blue-300"
        >
          Update Trek
        </button>
        <button
          onClick={()=>setShowEditModal(false)}
          className="w-full p-3  rounded-[10px] bg-gray-200 hover:bg-gray-300 focus:ring focus:ring-gray-300"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default EditTrek;