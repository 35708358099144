import { useState, useEffect, useContext } from "react";
import { FaGoogle, FaFacebookF } from "react-icons/fa";
import { IoEye, IoEyeOff } from "react-icons/io5";
import lock from "../assets/Lock.png";
import mail from "../assets/Mail.png";
import logo from "../assets/logo.png";
import {  toast } from "sonner";
import useAuth from "../services/useAuth.js";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../context/UserContext.jsx";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { api } = useAuth();
  const { saveUser } = useContext(UserContext);

  const persistAuthData = (accessToken, refreshToken, user) => {
    try {
      saveUser(user);

      // Store in localStorage
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
      localStorage.setItem("user", JSON.stringify(user));

      // Also store in sessionStorage for redundancy
      sessionStorage.setItem("accessToken", accessToken);
      sessionStorage.setItem("refreshToken", refreshToken);
      sessionStorage.setItem("user", JSON.stringify(user));

      // Set cookies as backup
      document.cookie = `accessToken=${accessToken}; path=/; max-age=86400; samesite=strict`;
      document.cookie = `refreshToken=${refreshToken}; path=/; max-age=604800; samesite=strict`;
    } catch (error) {
      console.error("Storage error:", error);
    }
  };

  // Handle Google OAuth redirect
  useEffect(() => {
    const handleOAuthCallback = async () => {
      const queryParams = new URLSearchParams(window.location.search);
      const accessToken = queryParams.get("accessToken");
      const refreshToken = queryParams.get("refreshToken");
      const userData = queryParams.get("user");

      if (accessToken && refreshToken && userData) {
        try {
          const user = JSON.parse(decodeURIComponent(userData));

          // Store tokens and user in localStorage
          localStorage.setItem("accessToken", accessToken);
          localStorage.setItem("refreshToken", refreshToken);
          localStorage.setItem("user", JSON.stringify(user));

          // Clear URL parameters
          window.history.replaceState(
            {},
            document.title,
            window.location.pathname
          );

          toast.success("Login successful! Redirecting...");
          setTimeout(() => {
            toast.dismiss();
            navigate("/");
          }, 1000);
        } catch (error) {
          console.error("OAuth callback error:", error);
          toast.error("Failed to process login");
        } finally {
          toast.dismiss();
        }
      }
    };

    handleOAuthCallback();
  }, [navigate]);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      if (!email || !password) {
        toast.error("Please fill out all fields");
        return;
      }

      const loadingToast = toast.loading("Please wait for login....");

      console.log("request is sended....");

      // Send login request
      const response = await api.post("/api/auth/login", {
        email,
        password,
      });

      console.log("we got the response....");

      // Handle successful login response
      if (response.data?.status === "success") {
        const accessToken =
          response.headers["x-access-token"] ||
          response.headers["X-Access-Token"];

        const refreshToken =
          response.headers["x-refresh-token"] ||
          response.headers["X-Refresh-Token"];

        const user = response.data.user;

        console.log("accesstoken :", accessToken);
        console.log("refreshToken :", refreshToken);
        console.log("user :", user);

        persistAuthData(accessToken, refreshToken, user);

        // console.log("access-token from login : "+accessToken);
        // console.log("refresh-token from login : "+refreshToken);
        // console.log("user Data from login : "+user);
        toast.dismiss(loadingToast);
        toast.success("Login successful! Redirecting...");

        // Small delay to ensure state updates are processed
        setTimeout(() => {
          navigate("/");
        }, 100);
      } else {
        toast.error(
          "Login failed: " + response.data?.message || "Unknown error"
        );
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Login failed due to an error";
      toast.error(errorMessage);
      console.error("Login error:", error);
    } finally {
      toast.dismiss();
      setIsLoading(false);
    }
  };

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Handle Google login
  const handleGoogleLogin = () => {
    try {
      window.location.href = `${import.meta.env.VITE_API_URL}/api/auth/google`;
    } catch (error) {
      console.error("Google login error:", error);
      toast.error("Failed to initiate Google login");
    } finally {
      toast.dismiss();
    }
  };

  return (
    <>
      

      <div className="flex flex-col lg:flex-row h-screen w-screen overflow-x-hidden lg:overflow-hidden">
        <div className="h-2/3 lg:h-full lg:w-1/3 flex items-center justify-center p-10 lg:p-20 bg-gradient-to-tr from-[#3d5c89] via-[#98d2ec] to-white">
          <a
            href="/"
            className="w-1/2 lg:w-full flex items-center justify-center"
          >
            <img src={logo} alt="logo" className="w-full" />
          </a>
        </div>

        <div className="w-full lg:w-2/3 flex h-full p-6 md:p-18 lg:p-32 items-center justify-center">
          <div className="flex flex-col items-start justify-center gap-4 w-full max-w-md md:max-w-lg p-6 md:p-10 lg:p-12 bg-white rounded-lg ">
            <h1 className="text-[30px] md:text-[35px] lg:text-[40px] font-semibold popp">
              Welcome back...!
            </h1>
            <h2 className="text-[16px] md:text-[18px] lg:text-[20px] popp">
              Sign in to your account
            </h2>

            <form
              onSubmit={handleSubmit}
              className="w-full flex flex-col gap-4"
            >
              {/* Email Input */}
              <div className="flex items-center justify-center border border-[#D0D5DD] rounded-[10px] px-4 relative w-full">
                <div className="w-[20px] flex items-center justify-center h-full">
                  <img src={mail} alt="mail" className="w-full top-3 left-3" />
                </div>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="admin@internetcompany.one"
                  className="w-full p-3 rounded-md focus:outline-none popp"
                />
              </div>

              {/* Password Input */}
              <div className="relative w-full flex items-center border border-[#D0D5DD] justify-center rounded-[10px] px-4">
                <div className="w-[20px] flex items-center justify-center h-full">
                  <img src={lock} alt="lock" className="w-full top-3 left-3" />
                </div>
                <input
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder={showPassword ? "Password" : "********"}
                  className="w-full p-2 rounded-md focus:outline-none popp"
                />
                <div
                  className="w-[20px] flex cursor-pointer items-center justify-center h-full"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? (
                    <IoEyeOff className="w-full" />
                  ) : (
                    <IoEye className="w-full" />
                  )}
                </div>
              </div>

              {/* Submit Button */}
              <button
                type="submit"
                className="w-full p-2 bg-[#4997D3] rounded-[10px] text-white hover:bg-[#3a7fbf] transition-all popp"
              >
                Continue
              </button>
            </form>

            <span className="w-full text-center font-bold popp">or</span>

            {/* Social Login */}
            <div className="flex flex-col w-full gap-4 items-center">
              {/* <button className="w-full p-2 text-[#565E6D] border border-[#565E6D] rounded-[10px] flex items-center justify-center gap-2 popp">
                <FaFacebookF className="text-[#565E6D]" /> Login with Facebook
              </button> */}
              <button
                onClick={handleGoogleLogin}
                className="w-full p-2 text-[#565E6D] border border-[#565E6D] rounded-[10px] flex items-center justify-center gap-2 popp"
              >
                <FaGoogle /> Login with Google
              </button>
            </div>

            <p className="w-full text-center text-sm mt-2 popp">
              By continuing, you agree to our{" "}
              <a href="#" className="text-[#171A1F] font-semibold popp">
                Terms of Service
              </a>{" "}
              and{" "}
              <a href="#" className="text-[#171A1F] font-semibold popp">
                Privacy Policy
              </a>
              .
            </p>
            <a
              href="/forgot-password"
              className="w-full text-sm text-center hover:underline transition-all text-[#4997D3]"
            >
              Forgot Password?
            </a>
            <span className="w-full text-center mt-1 popp text-sm">
              Don't have an account?{" "}
              <a href="/signup" className="text-[#4997D3] font-semibold popp">
                Signup
              </a>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
