import React, { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { User, Phone, Heart, Loader, AlertCircle, Users } from "lucide-react";
import { UserContext } from "../../context/UserContext";
import { toast } from "sonner";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
  LinkAuthenticationElement,
} from "@stripe/react-stripe-js";
import useAuth from "../../services/useAuth";
import StripePaymentHandler from "../Common/StripePaymentHandler";
const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);

const appearance = {
  theme: "stripe",
  variables: {
    colorPrimary: "#4997D3",
    colorBackground: "#ffffff",
    colorText: "#1a1f36",
    colorDanger: "#df1b41",
    borderRadius: "10px",
  },
};

// Validation utilities
const validatePhoneNumber = (phone) => {
  const phoneRegex = /^\+?[\d\s-]{10,}$/;
  return phoneRegex.test(phone);
};

const validateAge = (age) => {
  const numAge = parseInt(age);
  return numAge >= 5 && numAge <= 100;
};

const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const PaymentForm = ({ clientSecret, bookingId, onSuccess, onError }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);
  const [email, setEmail] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements || !clientSecret) {
      onError(new Error("Payment initialization failed. Please try again."));
      return;
    }

    setIsProcessingPayment(true);

    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/booking/confirmation/${bookingId}`,
          payment_method_data: {
            billing_details: {
              email: email,
            },
          },
          receipt_email: email,
        },
        redirect: "if_required",
      });

      if (error) {
        throw new Error(error.message || "Payment failed. Please try again.");
      }

      if (paymentIntent.status === "succeeded") {
        onSuccess(paymentIntent);
      } else if (paymentIntent.status === "requires_action") {
        const { error: actionError } = await stripe.handleNextAction({
          clientSecret: paymentIntent.client_secret,
        });
        if (actionError) {
          throw new Error(
            actionError.message || "Additional authentication failed."
          );
        }
      }
    } catch (error) {
      console.error("Payment error:", error);
      onError(error);
    } finally {
      setIsProcessingPayment(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <LinkAuthenticationElement
        onChange={(e) => setEmail(e?.value?.email || "")}
        options={{
          defaultValues: {
            email: email,
          },
        }}
      />
      <PaymentElement
        options={{
          layout: "tabs",
          paymentMethodOrder: ["card", "apple_pay", "google_pay"],
        }}
      />
      <button
        type="submit"
        disabled={isProcessingPayment || !stripe || !elements}
        className="w-full bg-[#4997D3] text-white py-3 rounded-[10px] hover:bg-[#4fabf1] disabled:bg-gray-400 disabled:cursor-not-allowed flex items-center justify-center"
      >
        {isProcessingPayment ? (
          <>
            <Loader className="animate-spin mr-2" />
            Processing Payment...
          </>
        ) : (
          "Pay Now"
        )}
      </button>
    </form>
  );
};

const InputField = ({
  icon: Icon,
  title,
  type = "text",
  value,
  onChange,
  required = false,
  placeholder,
  name,
  error,
  validate,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [localError, setLocalError] = useState("");

  const handleChange = (e) => {
    onChange(e);
    if (validate) {
      const validationError = validate(e.target.value);
      setLocalError(validationError || "");
    }
  };

  return (
    <div className="mb-4">
      <label className="block text-sm font-medium mb-1">{title}</label>
      <div
        className={`flex items-center border ${
          isFocused
            ? "ring-2 ring-blue-300 border-transparent"
            : "border-gray-300"
        } ${
          error || localError ? "border-red-500" : ""
        } rounded-[10px] px-3 transition-all duration-200`}
      >
        {Icon && (
          <Icon
            className={`w-5 h-5 ${
              isFocused ? "text-blue-400" : "text-gray-400"
            } mr-2`}
          />
        )}
        <input
          type={type}
          value={value}
          onChange={handleChange}
          required={required}
          placeholder={placeholder}
          name={name}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          className="w-full p-2 outline-none bg-transparent"
        />
      </div>
      {(error || localError) && (
        <p className="text-red-500 text-sm mt-1">{error || localError}</p>
      )}
    </div>
  );
};

const TrekBookingForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useContext(UserContext);
  const { api } = useAuth();

  // Initialize form data state with a default structure
  const [formData, setFormData] = useState({
    routeDetails: {
      routeIndex: 0,
      routeName: "",
      numberOfDays: 0,
      pricePerHead: 0,
    },
    bookingDetails: {
      startDate: "",
      endDate: "",
      totalGuests: 1,
    },
    guestDetails: {
      primaryGuest: {
        name: user?.name || "",
        age: "",
        gender: "Male",
        phone: "",
      },
      additionalGuests: [],
    },
    payment: {
      baseAmount: 0,
      finalAmount: 0,
    },
    additionalNotes: "",
  });

  const [formErrors, setFormErrors] = useState({
    primaryGuest: {},
    additionalGuests: [],
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState(null);

  useEffect(() => {
    // Validate required location state
    if (
      !location.state?.bookingData ||
      !location.state?.details ||
      !location.state?.selectedRoute
    ) {
      toast.error(
        "Missing booking information. Please start from the package details page."
      );
      navigate("/");
      return;
    }

    const { bookingData, details, selectedRoute } = location.state;

    // Find the correct route index
    const routeIndex = details.routes.findIndex(
      (route) => route.name === selectedRoute.name
    );
    if (routeIndex === -1) {
      toast.error("Invalid route selected");
      navigate("/");
      return;
    }

    // Update form data with booking information
    setFormData((prev) => ({
      ...prev,
      routeDetails: {
        routeIndex,
        routeName: selectedRoute.name,
        numberOfDays: selectedRoute.numberOfDays,
        pricePerHead: selectedRoute.pricePerHead,
      },
      bookingDetails: {
        startDate: bookingData.bookingDetails.startDate,
        endDate: bookingData.bookingDetails.endDate,
        totalGuests: bookingData.bookingDetails.totalGuests,
      },
      guestDetails: {
        primaryGuest: {
          name: user?.name || "",
          age: "",
          gender: "Male",
          phone: "",
        },
        additionalGuests: Array(
          Math.max(0, bookingData.bookingDetails.totalGuests - 1)
        )
          .fill(null)
          .map(() => ({
            name: "",
            age: "",
            gender: "Male",
            phone: "",
          })),
      },
      payment: bookingData.payment,
    }));
  }, [location.state, user, navigate]);

  // Move the calculateEndDate function before useState
  const calculateEndDate = (startDate, numberOfDays) => {
    if (!startDate || !numberOfDays) return "";

    try {
      const start = new Date(startDate);
      if (isNaN(start.getTime())) {
        throw new Error("Invalid start date");
      }

      const end = new Date(start);
      end.setDate(end.getDate() + (numberOfDays - 1));

      return end.toISOString().split("T")[0];
    } catch (error) {
      console.error("Error calculating end date:", error);
      return "";
    }
  };

  // Validation functions
  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^\+?[\d\s-]{10,}$/;
    return phoneRegex.test(phone);
  };

  const validateAge = (age) => {
    const numAge = parseInt(age);
    return numAge >= 5 && numAge <= 100;
  };

  const { bookingData, details, selectedRoute } = location.state || {};

  console.log("bookingData : ", bookingData);
  console.log("details : ", details);
  console.log("selectedRoute : ", selectedRoute);

  const validateGuest = (guest, isAdditional = false) => {
    const errors = {};

    if (!guest.name || guest.name.trim().length < 2) {
      errors.name = "Name must be at least 2 characters";
    }

    if (!guest.phone || !validatePhoneNumber(guest.phone)) {
      errors.phone = "Please enter a valid phone number";
    }

    if (!guest.age || !validateAge(guest.age)) {
      errors.age = "Age must be between 5 and 100 years";
    }

    return errors;
  };

  const handleInputChange = (section, field, event, index = null) => {
    if (!formData) return;

    const value = event.target.value;

    setFormData((prev) => {
      const newState = { ...prev };

      if (section === "primaryGuest") {
        newState.guestDetails.primaryGuest = {
          ...newState.guestDetails.primaryGuest,
          [field]: value,
        };
      } else if (section === "additionalGuests" && index !== null) {
        newState.guestDetails.additionalGuests =
          newState.guestDetails.additionalGuests.map((guest, i) =>
            i === index ? { ...guest, [field]: value } : guest
          );
      } else if (section === "additionalNotes") {
        newState.additionalNotes = value;
      }

      return newState;
    });

    // Clear error when user starts typing
    setFormErrors((prev) => {
      const newErrors = { ...prev };
      if (section === "primaryGuest") {
        newErrors.primaryGuest = {
          ...newErrors.primaryGuest,
          [field]: "",
        };
      } else if (section === "additionalGuests" && index !== null) {
        newErrors.additionalGuests = newErrors.additionalGuests.map(
          (errors, i) => (i === index ? { ...errors, [field]: "" } : errors)
        );
      }
      return newErrors;
    });
  };

  const validateForm = () => {
    if (!formData?.guestDetails) return false;

    const primaryGuestErrors = validateGuest(
      formData.guestDetails.primaryGuest
    );
    const additionalGuestsErrors = formData.guestDetails.additionalGuests.map(
      (guest) => validateGuest(guest, true)
    );

    setFormErrors({
      primaryGuest: primaryGuestErrors,
      additionalGuests: additionalGuestsErrors,
    });

    return (
      Object.keys(primaryGuestErrors).length === 0 &&
      additionalGuestsErrors.every((errors) => Object.keys(errors).length === 0)
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      toast.error("Please correct the errors in the form before proceeding.");
      return;
    }

    setIsSubmitting(true);

    try {
      // Validate user authentication
      if (!user?.id) {
        throw new Error("Please log in to complete your booking.");
      }

      const bookingRequest = {
        userId: user.id,
        trekId: location.state.details._id,
        routeDetails: {
          routeIndex: formData.routeDetails.routeIndex,
          name: formData.routeDetails.name, // Changed from routeName to name
          numberOfDays: formData.routeDetails.numberOfDays,
          pricePerHead: formData.routeDetails.pricePerHead,
        },
        bookingDetails: {
          // Restructured to match model
          startDate: new Date(formData.bookingDetails.startDate).toISOString(),
          endDate: new Date(formData.bookingDetails.endDate).toISOString(),
          totalGuests: formData.bookingDetails.totalGuests,
          status: "Pending",
        },
        guestDetails: {
          primaryGuest: {
            name: formData.guestDetails.primaryGuest.name,
            age: parseInt(formData.guestDetails.primaryGuest.age),
            gender: formData.guestDetails.primaryGuest.gender,
            phoneNumber: formData.guestDetails.primaryGuest.phone, // Changed from phone to phoneNumber
          },
          additionalGuests: formData.guestDetails.additionalGuests.map(
            (guest) => ({
              name: guest.name,
              age: parseInt(guest.age),
              gender: guest.gender,
              phoneNumber: guest.phone, // Changed from phone to phoneNumber
            })
          ),
        },
        payment: {
          baseAmount: formData.payment.baseAmount,
          finalAmount: formData.payment.finalAmount,
          status: "Pending", // Added status as required by model
          charges: {
            // Added charges structure from trek data
            gst: formData.payment.charges?.gst || 18,
            serviceFee: formData.payment.charges?.serviceFee || 5,
            insuranceFee: formData.payment.charges?.insuranceFee || 500,
            bookingFee: formData.payment.charges?.bookingFee || 200,
          },
        },
        additionalNotes: formData.additionalNotes,
      };

      console.log("start boking...");
      console.log("booking data : ", bookingRequest);

      const response = await api.post("/api/bookings", bookingRequest);

      

      const { data } = response.data;

      console.log("data : ",data);

      console.log("clientSecret : ",data?.clientSecret );
      console.log("paymentIntentId : ",data?.paymentIntentId );
      console.log("paymentIntentId : ",data.paymentBreakdown );

      if (!data?.clientSecret || !data?.paymentIntentId) {
        throw new Error('Invalid server response');
      }

      // Update state in a single operation
      const paymentData = {
        clientSecret: data.clientSecret,
        bookingId: data.paymentIntentId,
        paymentBreakdown: data.paymentBreakdown,
        id:data.booking._id
      };

      console.log("Setting payment info:", paymentData);
      setPaymentInfo(paymentData);

      // Verify state update
      setTimeout(() => {
        console.log("Payment info after update:", paymentData);
      }, 0);
     
    } catch (error) {
      console.error("Booking submission error:", error);

      if (error.response?.status === 401) {
        navigate("/login", { state: { from: location } });
      } else if (error.response?.status === 400) {
        const validationErrors = error.response.data.errors;
        if (validationErrors) {
          Object.entries(validationErrors).forEach(([field, message]) => {
            toast.error(`${field}: ${message}`);
          });
        } else {
          toast.error(error.response.data.message || "Invalid booking data");
        }
      } else if (error.response?.status === 409) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An unexpected error occurred. Please try again later.");
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handlePaymentSuccess = async (paymentIntent) => {
    try {
      console.log('Payment successful:', paymentIntent);
      console.log('verifyfromsucess:',);
  
      if (paymentIntent.status === "succeeded") {
        toast.success('Booking confirmed successfully!');
        console.log("paymentIntentId : ",paymentIntent.id);
        navigate(`/booking/confirmation/${paymentInfo.id}`,{
          state:{
            paymentIntent:paymentIntent
          }
        });
      } else {
        throw new Error('Failed to confirm booking');
      }
    } catch (error) {
      console.error('Error confirming booking:', error);
      toast.error('Payment successful but booking confirmation failed. Our team will contact you shortly.');
    }
  };

  const handlePaymentError = (error) => {
    console.error("Payment error:", error);
    toast.error(error.message || "Payment failed. Please try again.");
  };

  if (!bookingData || !details || !selectedRoute) {
    return null; // Early return handled by useEffect
  }

  return (
    <div className="max-w-4xl mx-auto p-3 sm:p-6 bg-gray-50">
      {/* Header section */}
      <div className="bg-white rounded-lg shadow-sm p-4 sm:p-6 mb-6 sm:mb-8">
        <h1 className="text-2xl sm:text-3xl font-bold mb-4">{details.name}</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 sm:gap-4 text-gray-600">
          <div className="flex items-center gap-2">
            <Users className="w-5 h-5" />
            <span className="text-sm sm:text-base">
              Route: {bookingData.routeDetails.routeName}
            </span>
          </div>
          <div className="flex items-center gap-2">
            <Users className="w-5 h-5" />
            <span className="text-sm sm:text-base">
              Guests: {bookingData.bookingDetails.totalGuests}
            </span>
          </div>
          <div className="flex items-center gap-2">
            <Heart className="w-5 h-5" />
            <span className="text-sm sm:text-base">
              Total: ${bookingData.payment.finalAmount.toFixed(2)}
            </span>
          </div>
        </div>
      </div>

      {!paymentInfo ? (
        <form onSubmit={handleSubmit} className="space-y-4 sm:space-y-6">
          {/* Primary Guest Details */}
          <div className="bg-white p-6 rounded-lg shadow-sm">
            <h2 className="text-xl font-semibold mb-6">
              Primary Guest Details
            </h2>
            <div className="grid md:grid-cols-2 gap-4">
              <InputField
                icon={User}
                title="Full Name"
                name="name"
                value={formData.guestDetails.primaryGuest.name}
                onChange={(e) => handleInputChange("primaryGuest", "name", e)}
                required
                placeholder="Enter full name"
                error={formErrors.primaryGuest.name}
              />
              <InputField
                icon={Phone}
                title="Phone Number"
                name="phone"
                value={formData.guestDetails.primaryGuest.phone}
                onChange={(e) => handleInputChange("primaryGuest", "phone", e)}
                required
                placeholder="Enter phone number"
                error={formErrors.primaryGuest.phone}
              />
              <InputField
                type="number"
                title="Age"
                name="age"
                value={formData.guestDetails.primaryGuest.age}
                onChange={(e) => handleInputChange("primaryGuest", "age", e)}
                required
                placeholder="Enter age"
                error={formErrors.primaryGuest.age}
              />
              <div className="mb-4">
                <label className="block text-sm font-medium mb-1">Gender</label>
                <select
                  value={formData.guestDetails.primaryGuest.gender}
                  onChange={(e) =>
                    handleInputChange("primaryGuest", "gender", {
                      target: { value: e.target.value },
                    })
                  }
                  className="w-full p-2 border border-gray-300 rounded-[10px] focus:ring-2 focus:ring-blue-300 outline-none"
                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>
          </div>

          {/* Additional Guests */}
          {formData.guestDetails.additionalGuests.map((guest, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-sm">
              <h2 className="text-xl font-semibold mb-6">
                Additional Guest {index + 1}
              </h2>
              <div className="grid md:grid-cols-2 gap-4">
                <InputField
                  icon={User}
                  title="Full Name"
                  name={`guest-${index}-name`}
                  value={guest.name}
                  onChange={(e) =>
                    handleInputChange("additionalGuests", "name", e, index)
                  }
                  required
                  placeholder="Enter full name"
                  error={formErrors.additionalGuests[index]?.name}
                />
                <InputField
                  icon={Phone}
                  title="Phone Number"
                  name={`guest-${index}-phone`}
                  value={guest.phone}
                  onChange={(e) =>
                    handleInputChange("additionalGuests", "phone", e, index)
                  }
                  required
                  placeholder="Enter phone number"
                  error={formErrors.additionalGuests[index]?.phone}
                />
                <InputField
                  type="number"
                  title="Age"
                  name={`guest-${index}-age`}
                  value={guest.age}
                  onChange={(e) =>
                    handleInputChange("additionalGuests", "age", e, index)
                  }
                  required
                  placeholder="Enter age"
                  error={formErrors.additionalGuests[index]?.age}
                />
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-1">
                    Gender
                  </label>
                  <select
                    value={guest.gender}
                    onChange={(e) =>
                      handleInputChange(
                        "additionalGuests",
                        "gender",
                        { target: { value: e.target.value } },
                        index
                      )
                    }
                    className="w-full p-2 border border-gray-300 rounded-[10px] focus:ring-2 focus:ring-blue-300 outline-none"
                  >
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>
            </div>
          ))}

          {/* Additional Notes */}
          <div className="bg-white p-6 rounded-lg shadow-sm">
            <h2 className="text-xl font-semibold mb-4">Additional Notes</h2>
            <textarea
              value={formData.additionalNotes}
              onChange={(e) =>
                handleInputChange("additionalNotes", null, {
                  target: { value: e.target.value },
                })
              }
              placeholder="Any special requirements or information..."
              className="w-full p-3 border border-gray-300 rounded-[10px] focus:ring-2 focus:ring-blue-300 outline-none h-32 resize-none"
            />
          </div>

          <button
            type="submit"
            disabled={isSubmitting}
            className="w-full bg-[#4997D3] text-white py-3 rounded-[10px] hover:bg-[#4fabf1] disabled:bg-gray-400 disabled:cursor-not-allowed flex items-center justify-center"
          >
            {isSubmitting ? (
              <>
                <Loader className="animate-spin mr-2" />
                Processing...
              </>
            ) : (
              "Continue to Payment"
            )}
          </button>
        </form>
      ) : (
        <div className="bg-white p-4 sm:p-6 rounded-lg shadow-sm">
          <h2 className="text-xl font-semibold mb-4">Payment Details</h2>

          <StripePaymentHandler
  clientSecret={paymentInfo.clientSecret}
  bookingId={paymentInfo.bookingId}
  id={paymentInfo.id}
  onPaymentSuccess={handlePaymentSuccess}
  onPaymentError={handlePaymentError}
  customerDetails={{
    name: formData.guestDetails.primaryGuest.name,
    email: user?.email,
    phone: formData.guestDetails.primaryGuest.phone
  }}
/>
       
        </div>
      )}
    </div>
  );
};

export default TrekBookingForm;
