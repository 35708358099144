import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { ChevronDown } from "lucide-react";
import { toast } from "sonner";
import DatePicker from "../Common/DateRangePicker";
import useAuth from "../../services/useAuth";

const HeroSection = ({ image, title, description, tagline }) => {
  const { isAuthenticated } = useAuth();
  const [formData, setFormData] = useState({
    destination: "",
    date: "",
    selectedTrek: null,
  });
  const [treks, setTreks] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [randomPackages, setRandomPackages] = useState([]);
  const navigate = useNavigate();
  const { api } = useAuth();

  const getRandomPackages = (treks, count) => {
    const shuffledTreks = [...treks];
    for (let i = shuffledTreks.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledTreks[i], shuffledTreks[j]] = [shuffledTreks[j], shuffledTreks[i]];
    }
    return shuffledTreks.slice(0, count);
  };

  useEffect(() => {
    const fetchTreks = async () => {
      try {
        const response = await api.get("/api/treks/getall");
        const trekData = response.data.data.treks;
        setTreks(trekData);
        const randomFivePackages = getRandomPackages(trekData, 5);
        setRandomPackages(randomFivePackages);
        setSearchResults(randomFivePackages); // Set initial search results
      } catch (error) {
        if (!isAuthenticated) {
          toast.error("Login and continue");
        } else {
          toast.error("Something went wrong");
        }
      } finally {
        toast.dismiss();
      }
    };
    fetchTreks();
  }, [api, isAuthenticated]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.search-container')) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleDestinationChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({ 
      ...prevData, 
      destination: value,
      selectedTrek: null 
    }));
    
    if (!value.trim()) {
      setSearchResults(randomPackages);
      setIsDropdownOpen(true);
      return;
    }

    const filtered = treks.filter((trek) =>
      trek?.name.toLowerCase().includes(value.toLowerCase())
    );
    setSearchResults(filtered);
    setIsDropdownOpen(true);
  };

  const handleDestinationSelect = (trek) => {
    setFormData((prevData) => ({
      ...prevData,
      destination: trek.name,
      selectedTrek: trek,
    }));
    setIsDropdownOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.selectedTrek) {
      toast.error("Please select a destination from the dropdown");
      return;
    }

    if (!formData.date) {
      toast.error("Please select a date");
      return;
    }

    const dateParam = encodeURIComponent(formData.date);
    navigate(`/package/${formData.selectedTrek._id}?date=${dateParam}`);
  };

  return (
    <>
      <div className="w-full flex flex-col items-center h-full">
        <div
          className="h-screen w-full"
          style={{
            backgroundImage: `url(${image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="flex flex-col justify-center items-center h-full">
            <motion.h1
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2, duration: 0.6 }}
              className="text-md osw tracking-widest uppercase lg:text-2xl font-bold text-white"
            >
              {tagline}
            </motion.h1>
            <motion.h1
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5, duration: 0.6 }}
              className="text-7xl osw lg:text-9xl font-bold text-white"
            >
              {title}
            </motion.h1>
            <motion.h1
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.8, duration: 0.6 }}
              className="text-md md:text-[16px] lg:text-lg popp text-white text-center max-w-sm md:max-w-2xl"
            >
              {description}
            </motion.h1>
          </div>
        </div>

        <div className="inputs px-4 md:px-0 flex w-full items-center justify-center">
          <form
            className="w-full xl:w-[60%] flex flex-col md:flex-row items-center text-white px-6 md:px-12 bg-inherit/40 backdrop-blur-2xl border border-zinc-50/20 -mt-48 md:-mt-20 rounded-[25px] py-8"
            onSubmit={handleSubmit}
          >
            <div className="w-full relative md:w-3/5 flex flex-col border-white/50 xl:px-2 xl:border-r search-container">
              <label
                htmlFor="destination"
                className="text-sm popp px-2 font-semibold"
              >
                Destination
              </label>

              <div className="relative">
                <input
                  type="text"
                  id="destination"
                  name="destination"
                  value={formData.destination}
                  onChange={handleDestinationChange}
                  onFocus={() => setIsDropdownOpen(true)}
                  className="mt-2 p-2 popp rounded-lg bg-transparent placeholder:text-zinc-50 focus:outline-none w-full pr-10"
                  placeholder="Enter destination"
                  autoComplete="off"
                />
                <ChevronDown
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                />
              </div>

              {isDropdownOpen && (
                <div className="absolute w-full top-16 left-0 mt-2 bg-white text-black rounded-lg overflow-y-auto max-h-40 z-10 shadow-lg custom-scrollbar flex flex-col">
                  {searchResults.length > 0 ? (
                    searchResults.map((trek) => (
                      <div
                        key={trek._id}
                        className="cursor-pointer border-b px-4 py-2 hover:bg-gray-200 last:border-b-0"
                        onClick={() => handleDestinationSelect(trek)}
                      >
                        {trek.name}
                      </div>
                    ))
                  ) : (
                    <div className="px-4 py-2 text-gray-500">
                      No destinations found
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className="w-full md:w-3/5 flex flex-col border-white/50 xl:px-6 xl:border-r">
              <label htmlFor="date" className="text-sm popp px-2 font-semibold">
                Date
              </label>
              <DatePicker 
                formData={formData} 
                setFormData={setFormData}
              />
            </div>

            <div className="w-full md:w-2/5 flex items-center justify-center lg:justify-end h-full mt-2 md:mt-0 md:ml-4">
              <button
                type="submit"
                className="bg-[#70B4E8] popp hover:bg-[#51acf2] transition-all text-white py-2 px-10 rounded-lg"
              >
                Search
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default HeroSection;