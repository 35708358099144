import React, { useState } from "react";
import PackageDetailOverview from "./PackageDetailOverview";
import PackageDetailHero from "./PackageDetailHero";
import PackageDetailForm from "./PackageDetailForm";
import MapView from "../MapView";
import PaymentPage from "../PaymentPage";
import RoutesDetail from "./RoutesDetail";

const PkgDetail = (props) => {
  const details = props.data;
  const initialDate = props.initialDate;
  const [payment, setPayment] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [bookingData, setBookingData] = useState(null);
  const [selectedRoute, setSelectedRoute] = useState(
    details?.routes?.[0] || null
  );

  // Handler for route selection
  const handleRouteSelection = (routeIndex) => {
    setSelectedRoute(details?.routes?.[routeIndex] || null);
  };

  return (
    <div className="w-full flex flex-col items-center mb-10">
      {payment && clientSecret ? (
        <PaymentPage
          details={details}
          clientSecret={clientSecret}
          bookingData={bookingData}
        />
      ) : (
        <div className="w-full flex flex-col items-center">
          {/* Hero Section */}
          <div
            className="h-screen w-full"
            style={{
              backgroundImage: `url(${details?.images.bannerImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="flex flex-col lg:pb-24 justify-center items-center h-full">
              <h1 className="text-7xl osw uppercase lg:text-9xl font-bold text-white">
                {details?.name}
              </h1>
              <h1 className="text-[16px] lg:text-lg popp text-white text-center max-w-2xl">
                {details?.description}
              </h1>
            </div>
          </div>

          {/* Main Content Section */}
          <div className="w-full md:w-10/12 px-6 xl:px-16 pt-32 py-10">
            <PackageDetailHero details={details} />

            {/* Content Layout with Sticky Form */}
            <div className="relative flex flex-col md:flex-row md:gap-20 py-8">
              {/* Left Column - Main Content */}
              <div className="w-full lg:w-2/3">
                <PackageDetailOverview details={details} />
                <hr className="w-full border-1 border-gray-200 my-10 mb-5" />
                <RoutesDetail
                  packageData={details}
                  activeRoute={
                    details?.routes?.findIndex(
                      (route) => route._id === selectedRoute?._id
                    ) || 0
                  }
                  onRouteSelect={handleRouteSelection}
                />
              </div>

              {/* Right Column - Sticky Form */}
              <div className="hidden lg:block w-1/3">
                <div className="sticky top-28">
                  <PackageDetailForm
                    details={details}
                    selectedRoute={selectedRoute}
                    setBookingData={setBookingData}
                    setPayment={setPayment}
                    clientSecret={clientSecret}
                    setClientSecret={setClientSecret}
                    initialDate={initialDate}
                  />
                </div>
              </div>

              {/* Mobile Form - Shows below content on small screens */}
              <div className="lg:hidden w-full mt-8 p-6">
                <PackageDetailForm
                  details={details}
                  selectedRoute={selectedRoute}
                  setBookingData={setBookingData}
                  setPayment={setPayment}
                  clientSecret={clientSecret}
                  setClientSecret={setClientSecret}
                  initialDate={initialDate}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PkgDetail;
