import { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { MdFormatListBulleted } from "react-icons/md";
import { GrMapLocation } from "react-icons/gr";
import { FiGrid } from "react-icons/fi";
import { IoSearchSharp } from "react-icons/io5";
import { TbChevronCompactLeft, TbChevronCompactRight } from "react-icons/tb";
import { toast } from "sonner";
import CustomDropdown from "../Common/CustomDropdown";
import { useLocation } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import MultipleMapLocations from "./MultipleMapLocations";
import useAuth from "../../services/useAuth";

const Packages = () => {
  const location = useLocation();
  const {api} = useAuth();
  const packageComponentRef = useRef(null);

  // State Management
  const [packages, setPackages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(4);
  const [viewType, setViewType] = useState("list");
  const [search, setSearch] = useState("");
  const [priceSortOrder, setPriceSortOrder] = useState("");
  const [reviewsSortOrder, setReviewsSortOrder] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Updated data transformation helper
  const transformTrekData = useCallback((trek) => {
    // Get the base price from the first route if available
    const basePrice = trek.routes?.[0]?.pricePerHead || 0;

    // Get coordinates from ending place instead of starting place
    const coordinates = trek.endingPlace?.coordinates || {
      latitude: 0,
      longitude: 0,
    };

    return {
      _id: trek._id,
      name: trek.name,
      description: trek.description,
      price: basePrice,
      location: `${trek.startingPlace?.name || ""} to ${
        trek.endingPlace?.name || ""
      }`,
      bannerImage: trek.images?.bannerImage || "",
      images: trek.images || {},
      features: trek.features || [],
      coordinates, // Now using end place coordinates
      routes: trek.routes?.[0] || {},
      reviews: {
        rating: "0.0",
        count: 0,
      },
      noOfDay: trek.routes?.[0]?.numberOfDays || 0,
      guests: trek.paymentDetails?.discount?.groupDiscountThreshold || 1,
      // Add end place specific information
      endPlace: {
        name: trek.endingPlace?.name || "",
        coordinates: trek.endingPlace?.coordinates || {
          latitude: 0,
          longitude: 0,
        }
      }
    };
  }, []);

  // Fetch packages
  // Fetch packages
  useEffect(() => {
    const fetchPackages = async () => {
      setLoading(true);
      setError(null);
      
      try {
        // Add request debugging
        console.log('Making API request to /api/treks/getall');
        
        const response = await api.get("/api/treks/getall", {
          // Add headers if needed
          headers: {
            'Content-Type': 'application/json',
            // Add any authentication headers here if required
          }
        });

        // Debug response
        console.log('API Response:', {
          status: response.status,
          statusText: response.statusText,
          headers: response.headers,
          data: response.data
        });

        if (!response.data) {
          throw new Error('No data received from the API');
        }

        if (!response.data.data?.treks) {
          throw new Error('Invalid data structure received from API');
        }

        const treks = response.data.data.treks;
        console.log('Received treks:', treks);

        const transformedTreks = treks.map(transformTrekData);
        console.log('Transformed treks:', transformedTreks);

        setPackages(transformedTreks);

      } catch (error) {
        // Enhanced error logging
        console.error('API Error Details:', {
          message: error.message,
          response: error.response?.data,
          status: error.response?.status,
          statusText: error.response?.statusText,
          config: {
            url: error.config?.url,
            method: error.config?.method,
            headers: error.config?.headers,
            baseURL: error.config?.baseURL
          }
        });

        // Set more informative error message
        const errorMessage = error.response?.data?.message 
          || error.message 
          || 'Failed to fetch packages';
        
        setError(errorMessage);
        toast.error(`Error: ${errorMessage}`);
      } finally {
        setLoading(false);
      }
    };

    fetchPackages();
  }, [api, transformTrekData]);

  // URL Parameter Extraction
  const getParams = useCallback(
    (param) => {
      const query = new URLSearchParams(location?.search);
      return query.get(param);
    },
    [location]
  );

  // Memoized Filtered Packages
  const filteredPackages = useMemo(() => {
    let tempPackages = [...packages];

    const destination = getParams("destination");
    const min = getParams("min");
    const max = getParams("max");

    if (destination) {
      window.scrollTo(0, 1000);
      tempPackages = tempPackages.filter(
        (p) =>
          p.name.toLowerCase().includes(destination.toLowerCase()) ||
          p.location.toLowerCase().includes(destination.toLowerCase())
      );
    }

    if (min && max) {
      tempPackages = tempPackages.filter(
        (p) => p.price >= Number(min) && p.price <= Number(max)
      );
    }

    if (search) {
      tempPackages = tempPackages.filter(
        (p) =>
          p.name.toLowerCase().includes(search.toLowerCase()) ||
          p.location.toLowerCase().includes(search.toLowerCase())
      );
    }

    if (priceSortOrder === "low-to-high") {
      tempPackages.sort((a, b) => a.price - b.price);
    } else if (priceSortOrder === "high-to-low") {
      tempPackages.sort((a, b) => b.price - a.price);
    }

    if (reviewsSortOrder === "low-to-high") {
      tempPackages.sort((a, b) => 
        Number(a.reviews.rating) - Number(b.reviews.rating)
      );
    } else if (reviewsSortOrder === "high-to-low") {
      tempPackages.sort((a, b) => 
        Number(b.reviews.rating) - Number(a.reviews.rating)
      );
    }

    return tempPackages;
  }, [packages, search, priceSortOrder, reviewsSortOrder, getParams]);

  // Pagination Calculations
  const totalPages = Math.ceil(filteredPackages.length / itemsPerPage);
  const indexOfLastPackage = currentPage * itemsPerPage;
  const indexOfFirstPackage = indexOfLastPackage - itemsPerPage;
  const currentPackages = filteredPackages.slice(
    indexOfFirstPackage,
    indexOfLastPackage
  );

  // Updated Map Packages transformation
  const mapPackages = useMemo(() => {
    return filteredPackages.map((pkg) => ({
      ...pkg,
      coordinates: {
        latitude: pkg.endPlace.coordinates.latitude || 0,
        longitude: pkg.endPlace.coordinates.longitude || 0,
      },
      // Add any additional properties needed for map markers
      title: pkg.name,
      description: `End point: ${pkg.endPlace.name}`,
    }));
  }, [filteredPackages]);

  console.log("mapPackages :",mapPackages);

  // Pagination Handlers
  const handleNextPage = useCallback(() => {
    setCurrentPage((prev) => {
      const newPage = Math.min(prev + 1, totalPages);
      if (newPage !== prev && packageComponentRef.current) {
        packageComponentRef.current.scrollIntoView({ behavior: 'smooth' });
      }
      return newPage;
    });
  }, [totalPages]);

  const handlePrevPage = useCallback(() => {
    setCurrentPage((prev) => {
      const newPage = Math.max(prev - 1, 1);
      if (newPage !== prev && packageComponentRef.current) {
        packageComponentRef.current.scrollIntoView({ behavior: 'smooth' });
      }
      return newPage;
    });
  }, []);

  const handlePageClick = useCallback((page) => {
    setCurrentPage((prev) => {
      if (page !== prev && packageComponentRef.current) {
        packageComponentRef.current.scrollIntoView({ behavior: 'smooth' });
      }
      return page;
    });
  }, []);

  // List View Renderer
  const renderListView = () => (
    <div className="w-full px-4 lg:px-0">
      {currentPackages.map((pkg) => (
        <a
          key={pkg._id}
          className="py-4 flex items-start space-x-4"
          href={`/package/${pkg._id}`}
        >
          <div className="w-[250px] h-[200px] md:w-[300px] md:h-[200px]">
            <img
              src={pkg.bannerImage}
              alt={pkg.name}
              className="w-full h-[200px] object-cover rounded-[20px]"
            />
          </div>
          <div className="flex items-start flex-col justify-between w-full h-[200px]">
            <div className="flex flex-col">
              <h3 className="popp capitalize text-[14px] md:text-[26px]">
                {pkg.name}
              </h3>
              <p className="popp text-sm md:text-md text-[#4997D3]">
                $ {pkg.price} / person
              </p>
            </div>
            <p className="popp text-xs md:text-sm">{pkg.description}</p>
            <div className="flex flex-col py-1 items-start">
              <p className="popp text-xs md:text-sm">
                {pkg.guests} guests • {pkg.noOfDay} Days
              </p>
              <p className="popp text-xs md:text-sm mt-1">
                {pkg.features.slice(0, 3).map((feature, index) => (
                  <span key={index} className="mr-2 popp">
                    {index > 0 && "• "} {feature}
                  </span>
                ))}
              </p>
            </div>
          </div>
        </a>
      ))}
    </div>
  );

  // Grid View Renderer
  const renderGridView = () => (
    <div className="grid popp grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
      {currentPackages.map((pkg) => (
        <a
          href={`/package/${pkg._id}`}
          key={pkg._id}
          className="p-4 border hover:shadow transition-all rounded-[10px]"
        >
          <img
            src={pkg.images.bannerImage}
            alt={pkg.name}
            className="w-full h-40 object-cover rounded-[8px] mb-4"
          />
          <h3 className="text-xl font-semibold capitalize">{pkg.name}</h3>
          <p>Location: {pkg.location}</p>
          <p>Price: ${pkg.price} / person</p>
          <p>Duration: {pkg.noOfDay} Days</p>
          <p className="mt-2 text-sm">{pkg.features.slice(0, 2).join(" • ")}</p>
        </a>
      ))}
    </div>
  );

  // Render Packages
  const renderPackages = () => {
    if (loading) {
      return (
        <div className="h-[50vh] flex items-center justify-center w-full">
          <ClipLoader size={50} color="#3498db" loading={loading} />
        </div>
      );
    }

    if (error) {
      return (
        <div className="w-full flex items-center justify-center text-red-500">
          {error}
        </div>
      );
    }

    if (currentPackages.length === 0) {
      return (
        <div className="w-full flex items-center justify-center">
          <h2 className="text-2xl">No packages found</h2>
        </div>
      );
    }

    return viewType === "map" ? null : viewType === "card" ? renderGridView() : renderListView();
  };

  return (
    <div className="w-full flex mt-[150px] mb-[150px] items-center justify-center">
      <div className="w-full md:w-10/12 flex flex-col md:flex-row">
        <div className="w-full md:w-full flex flex-col items-center justify-center xl:px-20 md:p-4">
          {/* Header Section */}
          <div className="flex items-center w-full flex-col gap-2">
            <div className="flex w-full px-8 py-8 lg:px-0 items-center justify-between">
              <h2 className="text-[18px] osw whitespace-nowrap lg:text-[28px] font-bold lg:mb-4">
                DESTINATIONS ({filteredPackages.length})
              </h2>
              <div className="flex border-b popp border-zinc-200 items-center">
                <input
                  type="text"
                  placeholder="Search..."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  className="text-[16px] focus:outline-none p-2 w-[150px] md:w-[300px] rounded"
                />
                <IoSearchSharp className="text-gray-950 text-xl lg:text-3xl" />
              </div>
            </div>

            {/* Filters and View Type */}
            <div className="flex w-full flex-wrap gap-2 justify-center md:justify-between items-center lg:mb-4">
              <div className="flex items-center gap-3">
                <CustomDropdown
                  options={[
                    { value: "", label: "Sort by Price" },
                    { value: "low-to-high", label: "Low to High" },
                    { value: "high-to-low", label: "High to Low" },
                  ]}
                  selectedValue={
                    priceSortOrder === "low-to-high"
                      ? "Low to High"
                      : priceSortOrder === "high-to-low"
                      ? "High to Low"
                      : "Sort by Price"
                  }
                  onChange={setPriceSortOrder}
                />
                <CustomDropdown
                  options={[
                    { value: "", label: "Sort by Reviews" },
                    { value: "low-to-high", label: "Low to High" },
                    { value: "high-to-low", label: "High to Low" },
                  ]}
                  selectedValue={
                    reviewsSortOrder === "low-to-high"
                      ? "Low to High"
                      : reviewsSortOrder === "high-to-low"
                      ? "High to Low"
                      : "Sort by Reviews"
                  }
                  onChange={setReviewsSortOrder}
                />
              </div>

              {/* View Type Buttons */}
              <div className="flex space-x-2 mb md:mb-0">
                <button
                  onClick={() => setViewType("list")}
                  className={`p-2 md:p-3 rounded ${
                    viewType === "list"
                      ? "bg-[#70B3E6] text-white"
                      : "bg-[#F5F5F5]"
                  }`}
                >
                  <MdFormatListBulleted />
                </button>
                <button
                  onClick={() => setViewType("card")}
                  className={`p-2 md:p-3 rounded ${
                    viewType === "card"
                      ? "bg-[#70B3E6] text-white"
                      : "bg-[#F5F5F5]"
                  }`}
                >
                  <FiGrid />
                </button>
                <button
                  onClick={() => setViewType("map")}
                  className={`p-2 md:p-3 rounded ${
                    viewType === "map"
                      ? "bg-[#70B3E6] text-white"
                      : "bg-[#F5F5F5]"
                  }`}
                >
                  <GrMapLocation />
                </button>
              </div>
            </div>
          </div>

          {/* Content Area */}
          <div className="w-full flex flex-col lg:flex-row gap-20 py-12">
            <div
              className={`w-full ${
                viewType === "map" ? "lg:w-1/2" : "lg:w-2/3"
              }`}
            >
              {renderPackages()}
              {/* Pagination */}
              {viewType !== "map" && currentPackages.length > 0 && (
                <div className="flex justify-center mt-4">
                  <button
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                    className="p-2 text-2xl rounded"
                  >
                    <TbChevronCompactLeft />
                  </button>
                  <div className="flex space-x-2">
                    {Array.from({ length: totalPages }, (_, i) => (
                      <button
                        key={i}
                        onClick={() => handlePageClick(i + 1)}
                        className={`p-2 ${
                          currentPage === i + 1
                            ? "text-black font-semibold text-2xl"
                            : "text-gray-400 text-2xl"
                        }`}
                      >
                        0{i + 1}
                      </button>
                    ))}
                  </div>
                  <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    className="p-2 text-2xl rounded"
                  >
                    <TbChevronCompactRight />
                  </button>
                </div>
              )}
            </div>

            {/* Map Section */}
            <div
              className={`w-full ${
                viewType === "map" ? "lg:w-1/2" : "lg:w-1/3"
              } h-[600px] ${viewType === "map" ? "block" : "hidden lg:block"}`}
            >
              {mapPackages.length > 0 && (
                <MultipleMapLocations locations={mapPackages} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Packages;