import { useNavigate } from "react-router-dom";
import axios from "axios";
import { UserContext } from "../context/UserContext.jsx";
import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import { toast } from "sonner";

// Singleton to manage authentication state across the app
const AuthManager = (() => {
  let instance = null;
  let authPromise = null;

  class AuthenticationManager {
    constructor() {
      this.isAuthenticated = false;
      this.user = null;
      this.listeners = new Set();
      this.hasCheckedInitialAuth = false;
    }

    // Register a listener for authentication changes
    subscribe(listener) {
      this.listeners.add(listener);
      return () => this.listeners.delete(listener);
    }

    // Notify all listeners of authentication state change
    notifyListeners() {
      this.listeners.forEach(listener => listener({
        isAuthenticated: this.isAuthenticated,
        user: this.user
      }));
    }

    // Perform authentication check only once
    async checkAuthentication(api, clearAuthData, saveUser) {
      // Prevent multiple authentication checks
      if (this.hasCheckedInitialAuth) {
        return this.isAuthenticated;
      }

      try {
        const accessToken = localStorage.getItem("accessToken");
        const storedUser = localStorage.getItem("user");

        if (accessToken && storedUser) {

          console.log("requesting to the server....");
          const response = await api.get("/api/auth/verifyUser");
          
          if (response.status === 200 && response.data.user) {
            console.log("authentication sucessfully completed...");
            this.isAuthenticated = true;
            this.user = response.data.user;
            saveUser(response.data.user);
            this.notifyListeners();
          } else {
            clearAuthData();
            this.isAuthenticated = false;
            this.user = null;
            this.notifyListeners();
          }
        } else {
          clearAuthData();
          this.isAuthenticated = false;
          this.user = null;
          this.notifyListeners();
        }

        this.hasCheckedInitialAuth = true;
        return this.isAuthenticated;
      } catch (error) {
        console.error("Authentication check failed:", error);
        clearAuthData();
        this.isAuthenticated = false;
        this.user = null;
        this.notifyListeners();
        this.hasCheckedInitialAuth = true;
        return false;
      }
    }

    // Logout method
    async logout(api, navigate, clearAuthData) {
      console.log("logout the user...");
      try {
        await api.post("/api/auth/logout");
      } catch (error) {
        toast.error("Logout failed", { description: error.message });
      } finally {
        clearAuthData();
        this.isAuthenticated = false;
        this.user = null;
        this.hasCheckedInitialAuth = false;
        this.notifyListeners();
        navigate("/login");
      }
    }

    // Singleton instance creation
    static getInstance() {
      if (!instance) {
        instance = new AuthenticationManager();
      }
      return instance;
    }
  }

  return AuthenticationManager;
})();

const useAuth = () => {
  const navigate = useNavigate();
  const { saveUser, clearUser, user } = useContext(UserContext);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const authManagerRef = useRef(null);
  const hasPerformedInitialCheck = useRef(false);

  const API_URL = import.meta.env.VITE_API_URL;

  console.log("api :",API_URL);

  // Memoized Axios instance
  const api = useMemo(() => {
    const instance = axios.create({
      baseURL: API_URL,
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });

    // Request interceptor
    instance.interceptors.request.use((config) => {
      const accessToken = localStorage.getItem("accessToken");
      const refreshToken = localStorage.getItem("refreshToken");

      if (accessToken) {
        config.headers["Authorization"] = `Bearer ${accessToken}`;
      }
  
      if (refreshToken) {
        config.headers["X-Refresh-Token"] = refreshToken;
      }

      return config;
    }, (error) => Promise.reject(error));

    return instance;
  }, [API_URL]);

  // Clear authentication data
  const clearAuthData = useCallback(() => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("user");
    clearUser();
    setIsAuthenticated(false);
  }, [clearUser]);

  // Logout functionality
  const logout = useCallback(async () => {
    const authManager = AuthManager.getInstance();
    await authManager.logout(api, navigate, clearAuthData);
  }, [navigate, api, clearAuthData]);

  // Check authentication status
  const checkAuthStatus = useCallback(async () => {

    console.log("validate the user...");
    // Prevent multiple checks
    if (hasPerformedInitialCheck.current) return;

    setIsLoading(true);
    
    // Get or create singleton auth manager
    if (!authManagerRef.current) {
      authManagerRef.current = AuthManager.getInstance();
    }

    try {
      const result = await authManagerRef.current.checkAuthentication(
        api, 
        clearAuthData, 
        saveUser
      );

      setIsAuthenticated(result);
      hasPerformedInitialCheck.current = true;
    } catch (error) {
      console.error("Authentication check error:", error);
    } finally {
      setIsLoading(false);
    }
  }, [api, saveUser, clearAuthData]);

  // Subscribe to authentication changes
  useEffect(() => {
    // Only perform initial check once
    if (!hasPerformedInitialCheck.current) {
      checkAuthStatus();
    }

    return () => {
      hasPerformedInitialCheck.current = false;
    };
  }, []); // Empty dependency array to run only once

  return {
    isAuthenticated,
    isLoading,
    user,
    logout,
    api,
  };
};

export default useAuth;