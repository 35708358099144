import React, { createContext, useState, useEffect } from 'react';

export const UserContext = createContext({
  user: null,
  saveUser: () => {},
  clearUser: () => {}
});

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    loadUserFromStorage();
  }, []);

  const loadUserFromStorage = () => {
    try {
      // Try localStorage
      let userData = localStorage.getItem('user');
      
      // Try sessionStorage if localStorage failed
      if (!userData) {
        userData = sessionStorage.getItem('user');
      }
      
      // Try cookies if both storages failed
      if (!userData) {
        const cookieData = document.cookie
          .split('; ')
          .find(row => row.startsWith('user='));
        if (cookieData) {
          userData = cookieData.split('=')[1];
        }
      }

      if (userData) {
        const parsedUser = JSON.parse(userData);
        setUser(parsedUser);
        ensureDataConsistency(parsedUser);
      }
    } catch (error) {
      console.error('Error loading user data:', error);
      clearAllStorage();
    }
  };

  const ensureDataConsistency = (userData) => {
    try {
      const userString = JSON.stringify(userData);
      
      // Update all storage methods
      localStorage.setItem('user', userString);
      sessionStorage.setItem('user', userString);
      document.cookie = `user=${userString}; path=/; max-age=86400; samesite=strict`;
      
      // Also ensure tokens are consistent
      const tokens = {
        accessToken: localStorage.getItem('accessToken'),
        refreshToken: localStorage.getItem('refreshToken')
      };
      
      if (tokens.accessToken && tokens.refreshToken) {
        sessionStorage.setItem('accessToken', tokens.accessToken);
        sessionStorage.setItem('refreshToken', tokens.refreshToken);
        document.cookie = `accessToken=${tokens.accessToken}; path=/; max-age=86400; samesite=strict`;
        document.cookie = `refreshToken=${tokens.refreshToken}; path=/; max-age=604800; samesite=strict`;
      }
    } catch (error) {
      console.error('Error ensuring data consistency:', error);
    }
  };

  const saveUser = (userData) => {
    try {
      if (userData) {
        setUser(userData);
        ensureDataConsistency(userData);
      }
    } catch (error) {
      console.error('Error saving user data:', error);
      // clearAllStorage();
    }
  };

  const clearAllStorage = () => {
    setUser(null);
    
    // Clear localStorage
    localStorage.removeItem('user');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    
    // Clear sessionStorage
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('refreshToken');
    
    // Clear cookies
    document.cookie = 'user=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT';
    document.cookie = 'accessToken=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT';
    document.cookie = 'refreshToken=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT';
  };

  return (
    <UserContext.Provider value={{ user, saveUser, clearUser: clearAllStorage }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};