import React, { useEffect, useState, useRef } from "react";
import CountUp from "react-countup";
import qualityImage from "../../assets/quality1.jpeg";
import useAuth from "../../services/useAuth";

const Quality = () => {
  const [startCounting, setStartCounting] = useState(false);
  const [achievementData, setAchievementData] = useState(null);
  const sectionRef = useRef(null);
  const { api } = useAuth();

  // Demo fallback data
  const fallbackData = {
    experienceYrs: 15,
    noOfLocation: 50,
    travelAwards: 25,
    totalClients: 1000
  };

  useEffect(() => {
    const fetchAchievements = async () => {
      try {
        const response = await api.get('/api/achievement/');
        
        // Check if response has the expected structure and contains data
        if (response?.data?.status === 'success' && response.data.data?.[0]) {
          setAchievementData(response.data.data[0]);
        } else {
          // If no data returned or unexpected structure, use fallback data
          setAchievementData(fallbackData);
        }
      } catch (error) {
        console.error('Error fetching achievement data:', error);
        // On error, use fallback data
        setAchievementData(fallbackData);
      }
    };

    fetchAchievements();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (sectionRef.current) {
        const sectionTop = sectionRef.current.getBoundingClientRect().top;
        const triggerPoint = window.innerHeight * 0.8;
        if (sectionTop < triggerPoint) {
          setStartCounting(true);
          window.removeEventListener("scroll", handleScroll);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const displayData = achievementData || fallbackData;

  return (
    <div ref={sectionRef} className="w-full flex items-center justify-center mt-[150px] py-12 md:p-12 md:px-20">
      <div
        className="w-full xl:w-4/5 relative md:rounded-3xl"
        style={{
          backgroundImage: `url(${qualityImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "600px",
        }}
      >
        <div className="absolute w-full h-full bg-black/40 top-0 left-0 md:px-20 py-20 md:rounded-3xl">
          <h1 className="text-center md:text-start text-[24px] text-3xl font-semibold text-white">
            ONLY THE BEST QUALITY FOR YOU
          </h1>
          <div className="w-full flex md:h-[80%] md:py-8 text-white justify-center items-center">
            <div className="w-[100%] md:w-1/2 h-full flex items-start justify-start">
              <h1 className="w-full text-center md:text-start text-[14px] md:text-lg">
                You deserve the ultimate best quality <br /> for your memorable
                experiences.
              </h1>
            </div>
            <div className="w-1/2 h-full hidden md:flex items-end justify-end">
              <h1 className="text-lg">
                Take a look at our numbers for our <br /> credibility. Let's
                have an adventure!
              </h1>
            </div>
          </div>
          <div className="w-full flex flex-col md:flex-row items-center py-8 md:py-0 text-white gap-6 md:gap-1">
            {startCounting && displayData && (
              <>
                <div className="w-full md:w-1/4 md:border-r border-white flex flex-col items-center justify-center gap-2">
                  <h1 className="text-2xl">
                    <CountUp start={0} end={displayData.experienceYrs} duration={4} suffix=" +" />
                  </h1>
                  <p>years of experience</p>
                </div>
                <div className="w-full md:w-1/4 md:border-r border-white flex flex-col items-center justify-center gap-2">
                  <h1 className="text-2xl">
                    <CountUp start={0} end={displayData.noOfLocation} duration={4} suffix=" +" />
                  </h1>
                  <p>destination locations</p>
                </div>
                <div className="w-full md:w-1/4 md:border-r border-white flex flex-col items-center justify-center gap-2">
                  <h1 className="text-2xl">
                    <CountUp start={0} end={displayData.travelAwards} duration={6} suffix=" +" />
                  </h1>
                  <p>tour & travel awards</p>
                </div>
                <div className="w-full md:w-1/4 md:border-white flex flex-col items-center justify-center gap-2">
                  <h1 className="text-2xl">
                    <CountUp start={0} end={displayData.totalClients} duration={3} />
                  </h1>
                  <p>delightened clients</p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quality;