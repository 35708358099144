import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

const generateInvoice = (bookingId, bookingDetails, userData) => {
  const doc = new jsPDF();
  const pageWidth = doc.internal.pageSize.width;

  // Styling constants
  const colors = {
    primary: [41, 128, 185],
    secondary: [52, 73, 94],
    light: [189, 195, 199]
  };

  // Header
  doc.setFillColor(...colors.primary);
  doc.rect(0, 0, pageWidth, 40, 'F');
  
  doc.setTextColor(255, 255, 255);
  doc.setFont('helvetica', 'bold');
  doc.setFontSize(24);
  doc.text('INVOICE', pageWidth / 2, 25, { align: 'center' });

  // Company Details
  doc.setTextColor(...colors.secondary);
  doc.setFontSize(12);
  doc.text('TrekBooking.com', 15, 55);
  doc.setFont('helvetica', 'normal');
  doc.setFontSize(10);
  doc.text('123 Adventure Street', 15, 62);
  doc.text('contact@trekbooking.com', 15, 69);

  // Invoice Details
  doc.setFont('helvetica', 'bold');
  doc.setFontSize(11);
  doc.text(`Invoice #: ${bookingId}`, pageWidth - 15, 55, { align: 'right' });
  doc.text(`Date: ${new Date().toLocaleDateString()}`, pageWidth - 15, 62, { align: 'right' });

  // Customer Details
  doc.setFillColor(...colors.light);
  doc.rect(15, 80, pageWidth - 30, 35, 'F');
  
  doc.setTextColor(...colors.secondary);
  doc.setFont('helvetica', 'bold');
  doc.text('Billed To:', 20, 90);
  doc.setFont('helvetica', 'normal');
  doc.text([
    userData.name,
    userData.email,
    userData.phone || 'N/A'
  ], 20, 98);

  // Trek Details
  const trekData = [
    ['Description', 'Days', 'Amount'],
    [
      `${bookingDetails.trekDetails.name} - ${bookingDetails.routeDetails.routeName}`,
      bookingDetails.routeDetails.numberOfDays,
      `$${bookingDetails.amount.toFixed(2)}`
    ]
  ];

  doc.autoTable({
    startY: 130,
    head: [trekData[0]],
    body: [trekData[1]],
    theme: 'grid',
    headStyles: {
      fillColor: colors.primary,
      textColor: [255, 255, 255],
      fontStyle: 'bold'
    },
    styles: {
      fontSize: 10,
      cellPadding: 5
    }
  });

  // Total Amount
  const finalY = doc.previousAutoTable.finalY + 10;
  doc.setFillColor(...colors.secondary);
  doc.rect(pageWidth - 90, finalY, 75, 20, 'F');
  doc.setTextColor(255, 255, 255);
  doc.setFont('helvetica', 'bold');
  doc.text(`Total: $${bookingDetails.amount.toFixed(2)}`, pageWidth - 20, finalY + 13, { align: 'right' });

  // Footer
  const footerText = 'Thank you for choosing TrekBooking.com for your adventure!';
  doc.setTextColor(...colors.secondary);
  doc.setFontSize(10);
  doc.text(footerText, pageWidth / 2, doc.internal.pageSize.height - 20, { align: 'center' });

  return doc;
};

export default generateInvoice;