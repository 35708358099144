import React, { useState, useEffect } from "react";
import { IoShareSocial } from "react-icons/io5";
import { IoMdHeartEmpty, IoMdClose } from "react-icons/io";
import { FaWhatsapp, FaFacebook } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";

const PackageDetailHero = ({ details }) => {
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isGalleryModalOpen, setIsGalleryModalOpen] = useState(false);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [copied, setCopied] = useState(false);

  const shareUrl = typeof window !== "undefined" ? window.location.href : "";

  useEffect(() => {
    if (isShareModalOpen || isGalleryModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isShareModalOpen, isGalleryModalOpen]);

  const handleCopy = async () => {
    if (shareUrl) {
      try {
        await navigator.clipboard.writeText(shareUrl);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      } catch (error) {
        console.error("Failed to copy URL:", error);
      }
    }
  };

  const toggleShareModal = () => setIsShareModalOpen(prev => !prev);
  const toggleGalleryModal = (e) => {
    if (e) {
      e.stopPropagation();
    }
    setIsGalleryModalOpen(prev => !prev);
    setActiveImageIndex(0);
  };

  const handleImageClick = (index) => {
    setActiveImageIndex(index);
  };

  const allImages = [
    details.images.bannerImage,
    ...(details.images.secondaryImages || [])
  ];

  const handlePrevImage = (e) => {
    e.stopPropagation();
    setActiveImageIndex(prev => (prev > 0 ? prev - 1 : allImages.length - 1));
  };

  const handleNextImage = (e) => {
    e.stopPropagation();
    setActiveImageIndex(prev => (prev < allImages.length - 1 ? prev + 1 : 0));
  };

  const previewImages = allImages.slice(1, 5);
  const hasMoreImages = allImages.length > 5;

  return (
    <>
      {/* Top Section: Reviews and Actions */}
      <div className="w-full flex justify-between items-center px-4 md:px-0">
        <div className="flex gap-4 items-center">
          <button
            className="flex gap-1.5 items-center text-blue-500 hover:text-blue-600 transition-colors text-sm md:text-md popp"
            onClick={toggleShareModal}
            aria-label="Share"
          >
            <IoShareSocial className="text-lg" />
            Share
          </button>

          {/* <button 
            className="flex gap-1.5 items-center text-blue-500 hover:text-blue-600 transition-colors text-sm md:text-md popp" 
            aria-label="Save"
          >
            <IoMdHeartEmpty className="text-lg" />
            Save
          </button> */}
        </div>
      </div>

      {/* Gallery Section */}
      <div className="gallery py-6 w-full px-4 md:px-0">
        <div className="w-full relative h-[600px]">
          <img
            src={details.images.bannerImage || "/default-banner.jpg"}
            alt="Main package banner"
            className="w-full h-full object-cover rounded-[20px]"
          />
          <div className="absolute top-0 left-0 w-full h-full flex flex-col items-center p-6 md:p-20 justify-between bg-black bg-opacity-20">
            <p className="text-white text-center uppercase tracking-wider text-sm md:text-base">GALLERY</p>
            <h1 className="text-4xl md:text-6xl lg:text-7xl osw text-center uppercase font-semibold text-white">
              {details.name || "Package"}
            </h1>
          </div>
        </div>

        <div className="mt-4 grid grid-cols-2 md:grid-cols-4 gap-2">
          {previewImages.map((photo, index) => (
            <div 
              key={index}
              className="relative cursor-pointer h-[200px]"
              onClick={() => {
                setActiveImageIndex(index + 1);
                toggleGalleryModal();
              }}
            >
              <img
                src={photo || "/default-image.jpg"}
                alt={`Gallery image ${index + 1}`}
                className="w-full h-full object-cover rounded-[16px] hover:opacity-90 transition-opacity"
              />
              {index === 3 && hasMoreImages && (
                <div className="absolute inset-0 bg-black bg-opacity-50 rounded-[16px] flex items-center justify-center">
                  <span className="text-white text-xl font-semibold">more+</span>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Gallery Modal */}
      <AnimatePresence>
        {isGalleryModalOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="fixed inset-0 bg-black/20 bg-opacity-90 z-50 mt-16 backdrop-blur"
            onClick={toggleGalleryModal}
          >
            <div className="w-full h-full max-w-7xl mx-auto p-4 flex flex-col items-center relative">
              {/* Close button - Updated positioning and z-index */}
              <motion.button
                whileTap={{scale: 0.6}}
                onClick={(e) => {
                  e.stopPropagation();
                  toggleGalleryModal();
                }}
                className="fixed top-20 right-10 text-white hover:text-gray-200 transition-colors p-2 bg-red-500 rounded-full z-[60]"
                aria-label="Close gallery"
              >
                <IoMdClose className="text-2xl" />
              </motion.button>

              {/* Main Image Container */}
              <div className="h-[calc(100vh-250px)] w-full flex items-center justify-center relative mt-8" onClick={(e) => e.stopPropagation()}>
                <img
                  src={allImages[activeImageIndex]}
                  alt={`Gallery image ${activeImageIndex + 1}`}
                  className="max-h-full w-auto object-contain"
                />
                
                {/* Navigation Buttons */}
                <motion.button
                  whileTap={{scale: 0.6}}
                  onClick={handlePrevImage}
                  className="absolute left-4 bg-white rounded-full p-4 text-black hover:text-gray-300 transition-colors"
                  aria-label="Previous image"
                >
                  ❮
                </motion.button>
                <motion.button
                  whileTap={{scale: 0.6}}
                  onClick={handleNextImage}
                  className="absolute right-4 bg-white rounded-full p-4 text-black hover:text-gray-300 transition-colors"
                  aria-label="Next image"
                >
                  ❯
                </motion.button>
              </div>

              {/* Thumbnails */}
              <div className="w-full mt-4 overflow-x-auto" onClick={(e) => e.stopPropagation()}>
                <div className="flex gap-2 justify-center min-w-min p-2">
                  {allImages.map((image, index) => (
                    <div
                      key={index}
                      className="relative h-20 w-32 flex-shrink-0"
                      onClick={() => handleImageClick(index)}
                    >
                      <img
                        src={image}
                        alt={`Thumbnail ${index + 1}`}
                        className={`h-full w-full object-cover cursor-pointer rounded-lg transition-all
                          ${activeImageIndex === index ? 'border-2 border-blue-500 opacity-100' : 'opacity-50 hover:opacity-75'}`}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Share Modal */}
      <AnimatePresence>
        {isShareModalOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-50 p-4 mt-16"
            onClick={toggleShareModal}
          >
            <motion.div
              initial={{ scale: 0.95 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.95 }}
              transition={{ duration: 0.2 }}
              className="bg-white rounded-[10px] p-6 w-full max-w-md"
              onClick={e => e.stopPropagation()}
            >
              <h2 className="text-lg font-semibold mb-4">Share this Package</h2>

              <div className="flex flex-col gap-4">
                <a
                  href={`https://wa.me/?text=${encodeURIComponent(shareUrl)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-green-600 hover:bg-green-700 transition-colors text-white p-3 font-semibold w-full rounded-[10px] flex items-center justify-center gap-2"
                >
                  <FaWhatsapp className="text-lg" />
                  Share on WhatsApp
                </a>

                <a
                  href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-blue-600 hover:bg-blue-700 transition-colors text-white p-3 font-semibold w-full rounded-[10px] flex items-center justify-center gap-2"
                >
                  <FaFacebook className="text-lg" />
                  Share on Facebook
                </a>

                <div className="flex items-center">
                  <input
                    type="text"
                    value={shareUrl}
                    readOnly
                    className="border border-gray-300 rounded-l-[10px] px-3 py-3 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                    aria-label="Share URL"
                  />
                  <button
                    onClick={handleCopy}
                    className={`px-4 py-3 font-medium transition-all ${
                      copied 
                        ? "bg-green-500 text-white" 
                        : "bg-blue-500 hover:bg-blue-600 text-white"
                    } rounded-r-[10px]`}
                  >
                    {copied ? "Copied!" : "Copy"}
                  </button>
                </div>
              </div>

              <button
                onClick={toggleShareModal}
                className="mt-6 py-2 px-4 bg-gray-700 hover:bg-gray-800 transition-colors text-white rounded-[10px] float-right"
              >
                Close
              </button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default PackageDetailHero;