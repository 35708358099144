import { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { ClipLoader } from "react-spinners";
import MapView from "../MapView";

const RoutesDetail = ({
  packageData,
  isLoading,
  error,
  activeRoute,
  onRouteSelect,
}) => {
  const [activeDay, setActiveDay] = useState(null);

  if (isLoading) {
    return (
      <div className="w-full flex justify-center items-center py-12">
        <ClipLoader size={40} color="#70B3E6" />
      </div>
    );
  }

  if (error) {
    return <div className="w-full text-center text-red-500 py-8">{error}</div>;
  }

  // Early return if no trek data or routes
  if (!packageData?.routes?.length) {
    return null;
  }

  const handleRouteClick = (index) => {
    onRouteSelect(index);
    setActiveDay(null);
  };

  const handleDayClick = (index) => {
    setActiveDay(activeDay === index ? null : index);
  };

  const currentRoute = packageData.routes[activeRoute];

  // Prepare data for MapView
  const mapViewData = {
    ...currentRoute,
    startingPlace: packageData.startingPlace,
    endingPlace: packageData.endingPlace,
  };

  return (
    <div className="w-full flex flex-col items-center py-1">
      <div className="w-full max-w-6xl py-4">
        <div className="bg-white rounded-xl py-2">
          {/* Title */}
          <h2 className="text-2xl popp text-left mb-8 osw">Available Routes</h2>

          {/* Routes Selection */}
          <div className="flex flex-wrap justify-start gap-7 mb-8">
            {packageData.routes.map((route, index) => (
              <button
                key={index}
                onClick={() => handleRouteClick(index)}
                className={`px-5 p-2 rounded-lg transition-all ${
                  activeRoute === index
                    ? "bg-[#70B3E6] text-white shadow-md"
                    : "bg-gray-100 hover:bg-gray-200"
                }`}
              >
                <div className="popp">{route.name}</div>
              </button>
            ))}
          </div>

          {currentRoute && (
            <div className="w-full pb-[10px] flex flex-col gap-5">
              <MapView currentRoute={mapViewData} />
            </div>
          )}

          {/* Route Details */}
          <div className="rounded-lg py-6">
            <div className="flex flex-col items-start lg:flex-row justify-between lg:items-center mb-6 gap-5 lg:gap-0">
              <div className="flex items-center gap-5">
                <div className="flex flex-row items-center gap-1">
                  <span className="font-normal">From</span>:
                  <span className="px-3 py-1 bg-[#EBF5FB] text-[#70B3E6] rounded-lg text-sm">
                    {packageData.startingPlace.name}
                  </span>
                </div>

                <div className="flex flex-row items-center gap-1">
                  <span className="font-normal">To</span>:
                  <span className="px-3 py-1 bg-[#EBF5FB] text-[#70B3E6] rounded-lg text-sm">
                    {packageData.endingPlace.name}
                  </span>
                </div>
              </div>
              <div className=" md:mt-0 flex gap-5">
                <div className="flex flex-row items-center gap-1">
                  <span className="font-normal">Days</span>:
                  <span>{currentRoute.numberOfDays}</span>
                </div>

                <div className="flex flex-row items-center gap-1">
                  <span className="font-normal">Nights</span>:
                  <span>{currentRoute.numberOfNights}</span>
                </div>
              </div>
            </div>

            {/* Daily Activities */}
            <div className="space-y-4">
              {currentRoute.dailyActivities.map((activity, index) => (
                <div
                  key={index}
                  className="border border-gray-200 rounded-lg bg-white"
                >
                  {/* Day Header */}
                  <button
                    onClick={() => handleDayClick(index)}
                    className="w-full px-6 py-4 flex items-center justify-between hover:bg-gray-50 transition-colors"
                  >
                    <div className="flex items-center gap-4">
                      <span className="font-semibold text-lg">
                        Day&nbsp;{index + 1}
                      </span>
                      <span className="text-gray-600 line-clamp-1">
                        {activity.description}
                      </span>
                    </div>
                    <div className="text-gray-500">
                      {activeDay === index ? (
                        <IoIosArrowUp size={24} />
                      ) : (
                        <IoIosArrowDown size={24} />
                      )}
                    </div>
                  </button>

                  {/* Day Content */}
                  {activeDay === index && (
                    <div className="border-t border-gray-200 p-6 bg-white">
                      <div className="flex flex-col gap-8">
                        {/* Journey Details */}
                        <div>
                          <h4 className="text-lg font-semibold mb-4">
                            Journey Details
                          </h4>
                          <div className="grid grid-cols-1 lg:grid-cols-3 gap-y-2">
                            <div className="flex flex-row items-center gap-1">
                              <span className="text-gray-600">Start:</span>
                              <span>{activity.startPlace.name}</span>
                            </div>

                            <div className="flex flex-row items-center gap-1">
                              <span className="text-gray-600">End:</span>
                              <span>{activity.endPlace.name}</span>
                            </div>

                            <div className="flex flex-row items-center gap-1">
                              <span className="text-gray-600">Mode:</span>
                              <span className="capitalize">
                                {activity.travelling}
                              </span>
                            </div>
                          </div>
                        </div>

                        <hr className="w-full border-1 border-gray-200 my-1" />

                        {/* Accommodation */}
                        <div>
                          <h4 className="text-lg font-semibold mb-4">
                            Accommodation
                          </h4>
                          <div className="space-y-3">
                            <div className="font-medium">
                              {activity.accommodation.title}
                            </div>
                            <p className="text-gray-600">
                              {activity.accommodation.description}
                            </p>
                            <div className="flex flex-wrap gap-2">
                              {activity.accommodation.features.map(
                                (feature, idx) => (
                                  <span
                                    key={idx}
                                    className="px-3 py-1 bg-[#EBF5FB] text-[#70B3E6] rounded-full text-sm"
                                  >
                                    {feature}
                                  </span>
                                )
                              )}
                            </div>
                          </div>
                        </div>

                        <hr className="w-full border-1 border-gray-200 my-1" />

                        {/* Meals */}
                        <div>
                          <h4 className="text-lg font-semibold mb-4">Meals</h4>
                          <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
                            {Object.entries(activity.foodDetails)
                              .filter(([key]) => !key.startsWith("_"))
                              .map(([meal, items], idx) => (
                                <div
                                  key={idx}
                                  className="bg-gray-50 rounded-lg p-4"
                                >
                                  <div className="font-medium capitalize mb-3">
                                    {meal}
                                  </div>
                                  <ul className="md:space-y-2 flex flex-col justify-start md:items-start md:gap-1 gap-2">
                                    {items.map((item, itemIdx) => (
                                      <li
                                        key={itemIdx}
                                        className="flex text-gray-600"
                                      >
                                        <span className="w-1.5 h-1.5 bg-[#70B3E6] rounded-full mr-2"></span>
                                        {item}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoutesDetail;
