import React, { useState, Suspense } from "react";
import { FaLongArrowAltUp as ArrowIcon } from "react-icons/fa";
import { motion } from "framer-motion";
import { MdOutlineKeyboardArrowUp as UpArrowIcon } from "react-icons/md";

const AddTrek = React.lazy(() =>
  import("../components/AdminDashboard/AddTrek")
);
const ManageTreks = React.lazy(() =>
  import("../components/AdminDashboard/ManageTreks")
);

const MyBookings = React.lazy(() =>
  import("../components/Dashboard/MyBookings")
);

const Achievement = React.lazy(() =>
  import("../components/AdminDashboard/Achievement")
);

const Bookings = React.lazy(() =>
  import("../components/AdminDashboard/Bookings")
);
const CustomTrek = React.lazy(() =>
  import("../components/AdminDashboard/CustomTrek")
);
import Navbar from "../components/Common/Navbar";
// import Footer from "../components/Common/Footer";

const AdminPage = () => {
  const [activeComponent, setActiveComponent] = useState("EditTrek");

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const renderComponent = () => {
    switch (activeComponent) {
      case "AddTrek":
        return <AddTrek />;
      case "EditTrek":
        return <ManageTreks />;
      case "ViewBookings":
        return <Bookings />;
      case "CustomTrek":
        return <CustomTrek />;
      case "MyBookings":
        return <MyBookings />;
      case "Achievement":
        return <Achievement />;
      default:
        return <AddTrek />;
    }
  };

  return (
    <>
      <div className="flex flex-col w-screen !overflow-x-hidden ">
        <Navbar />
        <div className="w-full flex flex-col px-2 lg:px-[120px] items-center my-[150px] ">
          <h1 className="text-3xl px-6 w-full text-center font-semibold mb-5">
            Admin Dashboard
          </h1>

          <div className="flex flex-col lg:flex-row w-full ">
            {/* Side Menu */}
            <div className="w-full lg:w-1/4 p-4 lg:p-2 bg-white rounded-md mb-4 lg:mb-0 sticky">
              <ul className="space-y-2">
                <li
                  className={`cursor-pointer p-2 px-4 rounded-md text-start ${
                    activeComponent === "EditTrek"
                      ? "font-semibold bg-zinc-100 px-6 text-black"
                      : "hover:bg-zinc-50 transition-all"
                  }`}
                  onClick={() => setActiveComponent("EditTrek")}
                >
                  Manage Treks
                </li>
                <li
                  className={`cursor-pointer p-2 px-4 rounded-md text-start ${
                    activeComponent === "AddTrek"
                      ? "font-semibold bg-zinc-100 px-6 text-black"
                      : "hover:bg-zinc-50 transition-all"
                  }`}
                  onClick={() => setActiveComponent("AddTrek")}
                >
                  Add New Trek
                </li>
                <li
                  className={`cursor-pointer p-2 px-4 rounded-md text-start ${
                    activeComponent === "ViewBookings"
                      ? "font-semibold bg-zinc-100 px-6 text-black"
                      : "hover:bg-zinc-50 transition-all"
                  }`}
                  onClick={() => setActiveComponent("ViewBookings")}
                >
                  View Bookings
                </li>
                <li
                  className={`cursor-pointer p-2 px-4 rounded-md text-start ${
                    activeComponent === "CustomTrek"
                      ? "font-semibold bg-zinc-100 px-6 text-black"
                      : "hover:bg-zinc-50 transition-all"
                  }`}
                  onClick={() => setActiveComponent("CustomTrek")}
                >
                  Custom Treks
                </li>
                <li
                  className={`cursor-pointer p-2 px-4 rounded-md text-start ${
                    activeComponent === "MyBookings"
                      ? "font-semibold bg-zinc-100 px-6 text-black"
                      : "hover:bg-zinc-50 transition-all"
                  }`}
                  onClick={() => setActiveComponent("MyBookings")}
                >
                  MyBookings
                </li>
                <li
                  className={`cursor-pointer p-2 px-4 rounded-md text-start ${
                    activeComponent === "Achievement"
                      ? "font-semibold bg-zinc-100 px-6 text-black"
                      : "hover:bg-zinc-50 transition-all"
                  }`}
                  onClick={() => setActiveComponent("Achievement")}
                >
                  Achievements
                </li>
              </ul>
            </div>

            {/* Content Area */}
            <main className="w-full lg:w-3/4 p-4 lg:p-6">
              <Suspense fallback={<div>Loading...</div>}>
                {renderComponent()}
              </Suspense>
            </main>

            {/* scroll top */}
            <motion.div
              whileTap={{ scale: 0.6 }}
              className="p-2 border border-gray-300 rounded-full max-sm:block text-[30px] fixed bottom-4 right-5 z-[999999] cursor-pointer bg-white"
              onClick={() => handleScrollTop()}
            >
              <UpArrowIcon />
            </motion.div>
          </div>
        </div>
        {/* <Footer /> */}
        <></>
      </div>
    </>
  );
};

export default AdminPage;
