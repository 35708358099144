import { useState, useContext, useEffect, useCallback, useRef } from 'react';
import { IoPerson } from 'react-icons/io5';
import { toast } from 'sonner';
import { UserContext } from '../../context/UserContext';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../services/useAuth';

const HoverDropdown = () => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const { user, clearUser } = useContext(UserContext);
  const role = user?.role;
  const navigate = useNavigate();
  const { api } = useAuth();
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (!user) {
      console.warn("User data is missing, check UserContext for correct setup.");
    }
  }, [user]);

  // Handle click outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    if (isDropdownVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdownVisible]);

  const handleDashboardClick = useCallback((e) => {
    e.stopPropagation();
    navigate(role === "admin" ? "/admin" : "/dashboard");
    setDropdownVisible(false);
  }, [navigate, role]);

  const handleLogout = async (e) => {
    e.stopPropagation();
    try {
      await api.post("/api/auth/logout");
      toast.success("Logout successful");
      clearUser();
      setDropdownVisible(false);
      navigate("/login");
    } catch (error) {
      toast.error("Logout failed");
      console.error("Logout failed", error);
    }
  };

  return (
    <div className="relative flex items-center">
      {!user ? (
        <button
          onClick={() => navigate("/signup")}
          className="flex items-center gap-2 px-4 py-2 text-white cursor-pointer rounded-lg hover:bg-white/10 transition-all duration-200"
        >
          <IoPerson className="text-xl" />
          <span className="hidden sm:inline">Sign In</span>
        </button>
      ) : (
        <div className="relative" ref={dropdownRef}>
          <button
            onClick={() => setDropdownVisible(!isDropdownVisible)}
            onMouseEnter={() => setDropdownVisible(true)}
            className="flex items-center gap-2 px-4 py-2 text-white cursor-pointer rounded-lg hover:bg-white/10 transition-all duration-200"
          >
            <IoPerson className="text-xl" />
            <span className="hidden sm:inline">{user.name || 'User'}</span>
          </button>

          {/* Dropdown Container */}
          <div
            onMouseLeave={() => setDropdownVisible(false)}
            className={`absolute right-0 mt-2 w-48 sm:w-56 bg-white rounded-lg shadow-lg overflow-hidden transition-all duration-200 ease-in-out ${
              isDropdownVisible 
                ? 'opacity-100 visible translate-y-0'
                : 'opacity-0 invisible -translate-y-2'
            }`}
          >
            <div className="py-1">
              <button
                onClick={handleDashboardClick}
                className="w-full px-4 py-3 text-left text-sm text-gray-700 hover:bg-gray-100 transition-colors duration-150 flex items-center gap-2"
              >
                <span className="whitespace-nowrap">
                  {role === "admin" ? "Admin Dashboard" : "User Dashboard"}
                </span>
              </button>
              
              <button
                onClick={handleLogout}
                className="w-full px-4 py-3 text-left text-sm text-gray-700 hover:bg-gray-100 transition-colors duration-150 flex items-center gap-2"
              >
                <span>Logout</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HoverDropdown;