import React from "react";
import { FaLocationDot } from "react-icons/fa6";
import { Link } from "react-router-dom";
import useAuth from "../../services/useAuth";

const Deals = () => {
  const [treks, setTreks] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const { api } = useAuth();

  React.useEffect(() => {
    const fetchTreks = async () => {
      try {
        const response = await api.get('/api/bookings/get-trending-treks');
        console.log("responseData of Deals:", response);
        
        // Check if response.data exists and contains treks
        if (response?.data?.treks) {
          setTreks(response.data.treks.slice(0, 3)); // Get top 3 trending treks
        } else if (response?.data?.data?.treks) {
          setTreks(response.data.data.treks.slice(0, 3)); // Alternative data structure
        } else {
          throw new Error('Invalid data structure in response');
        }
      } catch (err) {
        console.error('Error fetching treks:', err);
        setError('Failed to fetch trek data');
      } finally {
        setLoading(false);
      }
    };

    fetchTreks();
  }, []);

  if (loading) {
    return (
      <div className="w-full h-[420px] flex items-center justify-center">
        <p className="text-xl popp">Loading...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full h-[420px] flex items-center justify-center">
        <p className="text-xl popp text-red-500">{error}</p>
      </div>
    );
  }

  // Guard clause for empty treks array
  if (!treks || treks.length === 0) {
    return (
      <div className="w-full h-[420px] flex items-center justify-center">
        <p className="text-xl popp">No trending treks available</p>
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col items-center justify-center px-8 md:px-20 py-16 mt-[150px] pb-[150px]">
      <h1 className="text-center font-bold osw text-[32px] md:text-3xl mb-2">
        JACKPOT DEALS ON{" "}
        <span className="text-[#4997D3] uppercase">Trending</span> TREKS
      </h1>
      <p className="hidden md:flex text-md popp pb-8">
        Explore our top destinations right from our beloved top spots.
      </p>
      
      <div className="w-full xl:w-3/4 flex flex-col md:flex-row gap-6 items-start">
        {/* Left Side Card */}
        {treks[0] && (
          <Link 
            to={`/package/${treks[0]._id}`} 
            className="w-full md:w-1/4 hidden md:flex flex-col items-start gap-2"
          >
            <img
              src={treks[0].images?.bannerImage}
              alt={treks[0].name}
              className="w-full h-80 object-cover rounded-3xl"
            />
            <h1 className="text-[32px] osw font-semibold">{treks[0].name}</h1>
            <p className="flex items-center">
              <FaLocationDot className="mr-2 text-[#4997D3]" />
              <span className="popp">{treks[0].routes?.length || 0} routes</span>
            </p>
          </Link>
        )}

        {/* Active Middle Card */}
        {treks[1] && (
          <div className="w-full md:w-2/4 h-[420px] rounded-3xl relative overflow-hidden">
            <div className="absolute inset-0 bg-gradient-to-b from-black/0 to-black/80 bg-opacity-50 rounded-3xl flex flex-col justify-end items-start p-8">
              <div className="flex flex-row gap-5 md:gap-0 md:flex-col mb-2 md:mb-0 items-center md:items-start justify-end">
                <h1 className="text-[32px] osw text-white mb-0 md:mb-2">
                  {treks[1].name}
                </h1>
                <p className="flex items-center text-white mb-0 md:mb-[15px]">
                  <FaLocationDot className="mr-2 text-[#4997D3]" />
                  <span className="popp">{treks[1].routes?.length || 0} routes</span>
                </p>
              </div>
              <p className="text-[12px] md:text-sm popp mb-[20px] text-white">
                {treks[1].description}
              </p>

              <div className="flex text-sm gap-4">
                <Link
                  to={`/package/${treks[1]._id}`}
                  className="bg-white popp text-black hover:bg-transparent hover:text-white hover:outline outline-1 px-4 py-2 rounded-[5px]"
                >
                  Book Now
                </Link>
                <Link
                  to={`/package/${treks[1]._id}`}
                  className="hidden md:flex bg-transparent popp hover:bg-white text-white border border-white hover:text-black px-4 py-2 rounded-[5px] transition-all"
                >
                  Learn More
                </Link>
              </div>
            </div>
            <img
              src={treks[1].images?.bannerImage}
              alt={treks[1].name}
              className="w-full h-full object-cover rounded-3xl"
              style={{ backgroundSize: "cover", backgroundPosition: "center" }}
            />
          </div>
        )}

        {/* Mobile view for first trek */}
        {treks[0] && (
          <div className="w-full flex md:hidden h-[420px] rounded-3xl relative overflow-hidden">
            <div className="absolute inset-0 bg-gradient-to-b from-black/0 to-black/80 bg-opacity-50 rounded-3xl flex flex-col justify-end items-start p-8">
              <div className="flex flex-row gap-5 md:gap-0 md:flex-col mb-2 md:mb-0 items-center justify-end">
                <h1 className="text-[32px] osw text-white md:mb-2">{treks[0].name}</h1>
                <p className="flex items-center text-white mb-0 md:mb-[15px]">
                  <FaLocationDot className="mr-2 text-[#4997D3]" />
                  <span className="popp">{treks[0].routes?.length || 0} routes</span>
                </p>
              </div>
              <p className="text-[12px] md:text-sm popp mb-[20px] text-white">
                {treks[0].description}
              </p>

              <div className="flex text-sm gap-4">
                <Link
                  to={`/package/${treks[0]._id}`}
                  className="bg-white popp text-black px-4 py-2 rounded-[5px]"
                >
                  Book Now
                </Link>
                <Link
                  to={`/package/${treks[0]._id}`}
                  className="hidden md:flex bg-transparent popp hover:bg-white text-white border border-white hover:text-black px-4 py-2 rounded-[5px] transition-all"
                >
                  Learn More
                </Link>
              </div>
            </div>
            <img
              src={treks[0].images?.bannerImage}
              alt={treks[0].name}
              className="w-full h-full object-cover rounded-3xl"
              style={{ backgroundSize: "cover", backgroundPosition: "center" }}
            />
          </div>
        )}

        {/* Right Side Card */}
        {treks[2] && (
          <Link 
            to={`/package/${treks[2]._id}`} 
            className="w-1/4 hidden md:flex flex-col items-start gap-2"
          >
            <img
              src={treks[2].images?.bannerImage}
              alt={treks[2].name}
              className="w-full h-80 object-cover rounded-3xl"
            />
            <h1 className="text-[32px] osw font-semibold">{treks[2].name}</h1>
            <p className="flex items-center">
              <FaLocationDot className="mr-2 text-[#4997D3]" />
              <span className="popp">{treks[2].routes?.length || 0} routes</span>
            </p>
          </Link>
        )}
      </div>
    </div>
  );
};

export default Deals;